import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';
import { WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { CasesService, CasesTableData } from 'src/app/services/cases/cases.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/operators';
import { PartnerLogoService } from 'src/app/shared/services/partner-logo/partner-logo.service';

@Component({
  selector: 'app-cases-table',
  templateUrl: './cases-table.component.html',
  styleUrls: ['./cases-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CasesTableComponent implements OnChanges, OnDestroy {
  readonly dataSource = new MatTableDataSource<CasesTableData>();
  readonly filterHeader: string[] = [];
  displayedColumns: string[] = [
    'detailsToggle',
    'payeeId',
    'debtors',
    'status',
    'jurisdiction',
    'partnerLogo',
    'claimsSumAmount',
    'actions',
  ];

  copyTooltipDisabled = true;

  @Input() data: CasesTableData[];
  @Input() length?: number;
  @Input() set isDevModeActive(active: boolean) {
    const displayedColumns = this.displayedColumns.filter(c => c !== 'debug');
    if (active) {
      displayedColumns.push('debug');
    }
    this.displayedColumns = displayedColumns;
  }

  expandedElement: CasesTableData | null = null;
  showAllLogs = false;
  lockExpandedElement = false;

  @Input() pageSize: number;
  @Output() pageSizeChange = new EventEmitter<number>();

  @Input() pageIndex: number;
  @Output() pageIndexChange = new EventEmitter<number>();

  @Output() page = new EventEmitter<PageEvent>();
  @Output() deleted = new EventEmitter<never>();

  @Output() filterCase = new EventEmitter<string>();

  private readonly killSub = new Subject<void>();

  get getPartnerLogoName() { return this.partnerLogoService.getPartnerMiniLogoName; }

  constructor(
    private workflowService: WorkflowService,
    private casesService: CasesService,
    private router: Router,
    private partnerLogoService: PartnerLogoService,
  ) { }

  ngOnDestroy(): void {
    this.killSub.next();
    this.killSub.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.dataSource.data = changes.data.currentValue;
    }
  }

  pageChange(event: PageEvent) {
    if (this.pageSize !== event.pageSize) {
      this.pageSize = event.pageSize;
      this.pageSizeChange.emit(event.pageSize);
    }
    if (this.pageIndex !== event.pageIndex) {
      this.pageIndex = event.pageIndex;
      this.pageIndexChange.emit(event.pageIndex);
    }

    this.page.emit(event);
  }

  onActionsMenuOpen(element: CasesTableData) {
    this.lockExpandedElement = true;
  }

  onActionsMenuClosed() {
    this.lockExpandedElement = false;
  }

  async runTransitionWithDialog(element: CasesTableData, event: CasesTableData['actions'][number]) {
    const res = await this.workflowService.runTransitionWithDialog({
      dialog: event.dialog,
      caseId: element.id,
      transitionId: event.id,
    });
    if (res) {
      const fresh = await this.casesService.getCases({
        payee_case_reference_id: element.payee_case_reference_id,
        page: 1,
        per_page: 1,
      });
      if (fresh.data.length > 0) {
        Object.assign(element, fresh.data[0]);
      }
    }
  }

  updateExpandedElement(element: CasesTableData) {
    if (this.lockExpandedElement) {
      return;
    }

    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
    }
  }

  filterHistoricCase(element: string): void {
    this.filterCase.emit(element);
  }

  async copyText(text: string) {
    await navigator.clipboard.writeText(text);
  }

  async navigateToEditForm(element: CasesTableData): Promise<void> {
    await this.router.navigateByUrl(`case/${element.id}`);
  }

  async deleteCase(element: CasesTableData): Promise<void> {
    const dialogRef = this.casesService.openDeleteCaseDialog(element.id);

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe({
        next: async result => {
          if (result) {
            this.deleted.emit();
          }
        },
      });
  }

  readonly environment = environment;
}
