<div class="dialog-container">
  <!--close icon-->
  <div class="close" *ngIf="showCloseButton">
    <payee-icon
      class="pointer"
      [name]="'cross-black'"
      [mat-dialog-close]=""
    ></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">Új hitelezői fiók hozzáadása</div>

  <!--form-->
  <div class="top-margin-30">
    <div *ngIf="!isClientSelected">
      <payee-company-search-input-field
        [otherSelection]="false"
        [nameFormControl]="nameFormControl"
        [isDeletable]="false"
        [placeholder]="'Keress cégre vagy egyéni vállalkozásra'"
        [width]="'600px'"
        [height]="'68px'"
        (companySearchItemSelected)="onSelectClient($event)"
      >
      </payee-company-search-input-field>
    </div>

    <div *ngIf="isClientSelected">
      <!--completed view only with address and tax number-->
      <payee-completed-search-box
        [name]="nameFormControl.value"
        [address]="address"
        [taxNumber]="taxNumberFormControl.value"
        [isDeletable]="true"
        [isEditable]="false"
        (deleteButtonClick)="onDeleteClient()"
      >
      </payee-completed-search-box>

      <div *ngIf="isIndividual">
        <!--other details data title-->
        <div class="component-title top-margin-30">
          {{ nameFormControl.value }} további adatai
        </div>

        <!--other data component-->
        <div class="top-margin-10">
          <app-form-other-data
            [motherNameFormControl]="motherNameFormControl"
            [birthPlaceFormControl]="birthPlaceFormControl"
            [birthDateFormControl]="birthDateFormControl"
          >
          </app-form-other-data>
        </div>
      </div>

      <!--bank account data-->
      <div class="component-title top-margin-30">Bankszámla adatai</div>

      <!--other data component-->
      <div class="top-margin-10">
        <app-sk-bank-account-form
          [countryFormControl]="accountCountryFormControl"
          [accountHolderNameFormControl]="accountHolderNameFormControl"
          [accountNumberFormControl]="accountNumberFormControl"
        >
        </app-sk-bank-account-form>
      </div>
    </div>
  </div>

  <!--save button-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="loading"
      [disabled]="!isClientSelected"
      (click)="onSave()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
