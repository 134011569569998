<h2>{{ "CASE_FORM.FORMS.BANK_ACCOUNT.TITLE" | translate }}</h2>
<form [formGroup]="form" [class.valid]="form.valid">
  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="account_holder_name"
        [placeholder]="
          'CASE_FORM.FORMS.BANK_ACCOUNT.ACCOUNT_HOLDER_NAME_PLACEHOLDER'
            | translate
        "
      />
      <ng-container
        payee-error
        *ngIf="account_holder_name.invalid && account_holder_name.dirty"
      >
        <span *ngIf="account_holder_name.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="account_holder_name.hasError('unknown')">
          {{ account_holder_name.errors.unknown }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="account_number"
        [placeholder]="
          'CASE_FORM.FORMS.BANK_ACCOUNT.ACCOUNT_NUMBER_PLACEHOLDER' | translate
        "
      />
      <ng-container
        payee-error
        *ngIf="account_number.invalid && account_number.dirty"
      >
        <span *ngIf="account_number.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="account_number.hasError('pattern')">
          {{
            "ERROR_MESSAGES.INVALID_FORMAT_WITH_EXAMPLE"
              | translate
                : {
                    pattern: "HU39162001511856250100000000"
                  }
          }}
        </span>
        <span *ngIf="account_number.hasError('unknown')">
          {{ account_number.errors.unknown }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <!-- <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="bic"
        [placeholder]="'CASE_FORM.FORMS.BANK_ACCOUNT.BIC_PLACEHOLDER' | translate"
      />
      <ng-container
        payee-error
        *ngIf="bic.invalid && bic.dirty"
      >
        <span *ngIf="bic.hasError('required')">
          {{ 'ERROR_MESSAGES.FIELD_REQUIRED' | translate }}
        </span>
        <span *ngIf="bic.hasError('unknown')">
          {{ bic.errors.unknown }}
        </span>
      </ng-container>
    </payee-form-field>
  </div> -->
</form>
