import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ToneOfVoice } from 'src/types/model/schemas';
import { CompanySearchAutocompleteItem, CompanySearchService } from 'src/app/shared/company-search-service/company-search.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { GroupTypeEnum } from 'src/app/aa-new-form/form-steps/init-step/models/common/group-type-enum';
import moment, { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare const google: any;

export type ToneOfVoiceOption = {
  label: string;
  value: ToneOfVoice;
};

export type CreditCardOption = {
  label: string;
  value: boolean;
};

export type CountryOption = {
  code: string;
  label: string;
  iconUrl: string;
};

@Component({
  selector: 'app-add-new-client-form',
  templateUrl: './add-new-client-form.component.html',
  styleUrls: ['./add-new-client-form.component.scss']
})
export class AddNewClientFormComponent implements OnInit, OnDestroy {
  readonly clientForms: FormArray;
  private readonly destroy = new Subject<void>();

  countries: CountryOption[] = [{
    code: 'HU',
    label: 'COUNTRY_NAMES.HU',
    // @todo fix
    iconUrl: 'https://firebasestorage.googleapis.com/v0/b/dev-app-payee.appspot.com/o/countries%2FHU.svg?alt=media&token=8a5b22f0-3821-4476-a800-36c1c93557b0',
  }];

  readonly tovs: ToneOfVoiceOption[] = [{
    label: 'ADD_NEW_CLIENT.FORM.COMMUNICATION.TOV.FORMAL',
    value: 'formal',
  }, {
    label: 'ADD_NEW_CLIENT.FORM.COMMUNICATION.TOV.FRIENDLY',
    value: 'friendly',
  }];

  readonly creditCardOptions: CreditCardOption[] = [{
    label: 'ADD_NEW_CLIENT.FORM.COMMUNICATION.BANK_ACCOUNT_OPTION.ALLOW',
    value: true,
  }, {
    label: 'ADD_NEW_CLIENT.FORM.COMMUNICATION.BANK_ACCOUNT_OPTION.DENY',
    value: false,
  }];

  // tslint:disable-next-line: variable-name
  private _clientType: 'ind' | 'org';
  set clientType(type: 'ind' | 'org') {
    if (this.clientType === type) {
      return;
    }
    this._clientType = type;

    // The only difference between the types is the first form group
    if (type === 'ind') {
      this.clientForms.setControl(0, this.indBasicsForm);
      this.bankAccountForm.reset();
      this.addressForm.reset();
    } else {
      this.clientForms.setControl(0, this.orgBasicsForm);
    }
  }
  get clientType() { return this._clientType; }
  get isClientInd(): boolean { return this.clientType === 'ind'; }

  readonly orgBasicsForm: FormGroup;
  readonly indBasicsForm: FormGroup;
  readonly addressForm: FormGroup;
  readonly bankAccountForm: FormGroup;
  readonly toneOfVoiceForm: FormGroup;

  get createClientLoading() { return this.clientsService.createClientLoading; }

  selectedIndex = 0;

  get leaveLink() { return this.clientsService.clientsSubject.value?.length > 0 ? '/user/user-profile' : '/user/add-new-client-onboarding'; }

  // tslint:disable-next-line
  private _addresstext: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren('addresstext') set addresstext(values: QueryList<ElementRef<HTMLInputElement>>) {
    this._addresstext = values;
    this.getPlaceAutocomplete();
  }
  get addresstext() { return this._addresstext; }

  readonly formatBankAccount = this.helpersService.formatBankAccount;

  readonly isSzamlazzhu: boolean;

  constructor(
    private fb: FormBuilder,
    private companySearchService: CompanySearchService,
    private clientsService: ClientsService,
    private router: Router,
    private titleService: Title,
    private helpersService: HelpersService,
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    this.orgBasicsForm = this.fb.group({
      name: ['', Validators.required],
      taxNumber: ['', Validators.required],
      registrationNumber: ['', Validators.required],
    });

    this.indBasicsForm = this.fb.group({
      name: ['', Validators.required],
      taxNumber: ['', []],
      mothersName: ['', Validators.required],
      birthPlace: ['', Validators.required],
      birthDate: ['', Validators.required],
    });

    this.addressForm = this.fb.group({
      country: [this.countries[0], Validators.required],
      postcode: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      settlement: ['', Validators.required],
      street: ['', Validators.required],
    });

    this.bankAccountForm = this.fb.group({
      beneficiaryName: ['', Validators.required],
      bankAccount: ['', [Validators.required, this.helpersService.bankAccountValidator]],
      bankName: ['', Validators.required],
    });

    this.toneOfVoiceForm = this.fb.group({
      toneOfVoice: [this.tovs[0], Validators.required],
      creditCardPayment: [this.creditCardOptions[0], Validators.required],
    });

    this.clientForms = this.fb.array([
      this.orgBasicsForm,
      this.addressForm,
      this.bankAccountForm,
      this.toneOfVoiceForm,
    ]);
    this.clientType = 'org';

    this.isSzamlazzhu = this.authService.origin === 'PLUGIN_SZAMLAZZHU';
  }

  get name() {
    if (this.clientType === 'ind') {
      return this.indBasicsForm.get('name');
    } else {
      return this.orgBasicsForm.get('name');
    }
  }

  // orgBasics
  get taxNumber() { return this.orgBasicsForm.get('taxNumber'); }
  get registrationNumber() { return this.orgBasicsForm.get('registrationNumber'); }

  // indBasics
  get mothersName() { return this.indBasicsForm.get('mothersName'); }
  get birthPlace() { return this.indBasicsForm.get('birthPlace'); }
  get birthDate() { return this.indBasicsForm.get('birthDate'); }

  // address
  get country() { return this.addressForm.get('country'); }
  get postcode() { return this.addressForm.get('postcode'); }
  get settlement() { return this.addressForm.get('settlement'); }
  get street() { return this.addressForm.get('street'); }

  // bank account
  get beneficiaryName() { return this.bankAccountForm.get('beneficiaryName'); }
  get bankAccount() { return this.bankAccountForm.get('bankAccount'); }
  get bankName() { return this.bankAccountForm.get('bankName'); }

  // tone of voice
  get toneOfVoice() { return this.toneOfVoiceForm.get('toneOfVoice'); }
  get creditCardPayment() { return this.toneOfVoiceForm.get('creditCardPayment'); }

  get clientName() {
    const name: string = this.name.value;

    if (name.length > 17) {
      return name.trim().slice(0, 17) + '(...)';
    }
    return name.trim();
  }

  ngOnInit(): void {
    this.translate.stream('BROWSER_TITLES.ADD_NEW_CLIENT_FORM')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async fillWithCompanyValues(autocompleteItem: CompanySearchAutocompleteItem | string) {
    console.log('Filling with company values', autocompleteItem);
    if (this.companySearchService.detailedSearchLoading) {
      console.log('search already loadinh');
      return;
    }

    if (typeof autocompleteItem === 'string') {
      this.clientType = 'org';
      this.orgBasicsForm.patchValue({
        name: autocompleteItem,
      });
      return;
    }

    const { company } = await this.companySearchService.detailedSearch(autocompleteItem.tax_number);
    console.log(company);

    this.clientType = company.basicData.type;

    const name = company.basicData.name || company.basicData.long_name;
    console.log({ name });

    if (this.clientType === 'ind') {
      console.log('Patching ind');
      this.indBasicsForm.patchValue({
        name,
        taxNumber: autocompleteItem.tax_number,
      });
    } else {
      console.log('Patching org');
      this.orgBasicsForm.patchValue({
        name,
        taxNumber: autocompleteItem.tax_number,
        registrationNumber: company.basicData.registrationNumber,
      });
    }

    const address = company.addressData;
    this.addressForm.patchValue({
      ...address,
      // country: this.countries.find(c => c.label === address.country),
      country: this.countries[0],
    });

    this.bankAccountForm.patchValue({
      beneficiaryName: company.bankAccountData?.account_holder_name ?? '',
      bankAccount: company.bankAccountData?.account_number ?? '',
      bankName: company.bankAccountData?.bank_name ?? '',
    });
  }

  fillWithIndValues(name: string) {
    this.clientType = 'ind';
    this.name.patchValue(name);
    this.addressForm.patchValue({
      country: this.countries[0]
    });
  }

  creditCardDisplayWith(x: CreditCardOption) {
    return x?.label;
  }

  async onSubmit(): Promise<void> {
    if (this.clientForms.invalid) {
      return;
    }

    await this.clientsService.createNewClient({
      account_holder_name: this.beneficiaryName.value,
      account_number: this.bankAccount.value,
      birth_date: this.formatDate(this.birthDate.value, 'YYYY-MM-DD'),
      birth_place: this.birthPlace.value,
      mother_name: this.mothersName.value,
      tax_number: this.taxNumber.value,
      type: this.clientType === 'ind' ?
        GroupTypeEnum.INDIVIDUAL_WITH_TAX_NUMBER
        : GroupTypeEnum.OTHER_WITH_TAX_NUMBER,
    });

    await this.router.navigateByUrl('/user/add-new-client-success');
  }

  private formatDate(date: string | Moment, format): string | null {
    if (!date) {
      return null;
    }
    if (moment.isMoment(date)) {
      return date.format(format);
    }
    return moment(date).format(format);
  }

  getAddress(place: any): void {
    const street = `${this.getStreet(place) || ''} ${this.getStreetNumber(place) || ''}`;
    const postcode = this.getPostCode(place) || '';
    const settlement = this.getSettlement(place) || '';
    this.addressForm.patchValue({
      street,
      postcode,
      settlement,
    });
  }

  private getPlaceAutocomplete(): void {
    const country = this.country.value as CountryOption;
    this.addresstext.forEach(element => {
      const autocomplete = new google.maps.places.Autocomplete(element.nativeElement, {
        componentRestrictions: { country: country?.code || 'HU' },
        type: 'postal_code',
        types: ['(regions)'],
        fields: ['address_components'],
      });

      element.nativeElement.onkeydown = () => {
        autocomplete.setComponentRestrictions({
          country: country?.code || 'HU',
        });
      };

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.getAddress(place);
      });
    });
  }

  getAddrComponent(place, componentTemplate): any {
    let result;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place): any {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' };
    const streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getStreet(place): any {
    const COMPONENT_TEMPLATE = { route: 'long_name' };
    const street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getSettlement(place): any {
    const COMPONENT_TEMPLATE = { locality: 'long_name' };
    const settlement = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return settlement;
  }

  getState(place): any {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' };
    const state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getDistrict(place): any {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' };
    const state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getCountryShort(place): any {
    const COMPONENT_TEMPLATE = { country: 'short_name' };
    const countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }

  getCountry(place): any {
    const COMPONENT_TEMPLATE = { country: 'long_name' };
    const country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place): any {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' };
    const postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }
}
