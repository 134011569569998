import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { ClientsService } from '../clients/clients.service';
import { BehaviorSubject } from 'rxjs';

export type CountryItem = {
  iso: string;
  name: BehaviorSubject<string>;
};

export type PartyTypeItem = {
  id: string;
  label: string;
  type: 'ind' | 'org';
};

@Injectable({
  providedIn: 'root'
})
export class EnumService {
  private countries?: { iso: string }[];

  constructor(
    private translate: TranslateService,
    private clientsService: ClientsService,
  ) { }

  async getPartyTypes(): Promise<PartyTypeItem[]> {
    const url = `${environment.baseUrl}/api/enum/party-types`;
    const result = await axios.get(url);
    return result.data.party_types;
  }

  async getCountries(): Promise<CountryItem[]> {
    if (!this.countries) {
      const url = `${environment.baseUrl}/api/case-form/countries`;
      const result = await axios.get<{ countries: { iso: string }[] }>(url);
      this.countries = result.data.countries;
    }

    return this.countries.map(({ iso }) => {
      const stream = this.translate.stream(`COUNTRY_NAMES.${iso}`);
      const subject = new BehaviorSubject<string>(this.translate.instant(`COUNTRY_NAMES.${iso}`));
      stream.subscribe(subject);
      return {
        iso,
        name: subject,
      };
    });
  }
}
