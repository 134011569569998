import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { USER_FORM_NAME } from 'src/app/services/form/form.factory';
import { FormService } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-user-email-form',
  templateUrl: './user-email-form.component.html',
  styleUrls: ['./user-email-form.component.scss']
})
export class UserEmailFormComponent {
  get form(): FormGroup { return this.formService.form.get(USER_FORM_NAME) as FormGroup; }
  get userEmail(): FormControl { return this.form.get('email') as FormControl; }
  get terms_of_service(): FormControl { return this.form.get('terms_of_service') as FormControl; }

  constructor(
    private formService: FormService,
  ) { }
}
