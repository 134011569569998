import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CountryItem, EnumService, PartyTypeItem } from 'src/app/services/enum/enum.service';
import { CLIENT_FORM_NAME } from 'src/app/services/form/form.factory';
import { FormService } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent implements OnInit {
  @Input() caseId: string;
  get form(): FormGroup { return this.formService.form.get(CLIENT_FORM_NAME) as FormGroup; }

  loading = true;
  partyTypeOptions: PartyTypeItem[] = [];
  countryOptions: CountryItem[] = [];

  collapsed: boolean = this.form.valid;

  get client_type(): FormControl { return this.form.get('client_type') as FormControl; }
  get addressString(): string {
    const address = this.form.value.address;
    return `${address.postcode} ${address.settlement} ${address.street}`;
  }

  constructor(
    private formService: FormService,
    private enumService: EnumService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    try {
      const [
        partyTypes,
        countries,
      ] = await Promise.all([
        this.enumService.getPartyTypes(),
        this.enumService.getCountries(),
      ]);
      this.partyTypeOptions = partyTypes;
      this.countryOptions = countries;
    } catch (error) {
      console.error('Error while loading enums', error);
    } finally {
      this.loading = false;
    }
  }
}
