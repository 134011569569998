import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { CountryItem, PartyTypeItem } from 'src/app/services/enum/enum.service';
import { DEBTOR_FORM_NAME } from 'src/app/services/form/form.factory';
import { FormService } from 'src/app/services/form/form.service';
import { CompanySearchAutocompleteItem, CompanySearchService } from 'src/app/shared/company-search-service/company-search.service';

@Component({
  selector: 'app-debtor-org-form',
  templateUrl: './debtor-org-form.component.html',
  styleUrls: ['./debtor-org-form.component.scss']
})
export class DebtorOrgFormComponent implements OnInit, OnDestroy {
  get form(): FormGroup { return this.formService.form.get(DEBTOR_FORM_NAME) as FormGroup; }

  companySearchOptions: CompanySearchAutocompleteItem[] = [];
  @Output() validCompanySelected = new EventEmitter<void>();

  debtorCountry = new BehaviorSubject<CountryItem | null>(null);
  @Input() countryOptions: CountryItem[] = [];

  private readonly destroy = new Subject<void>();
  get autocompleteSearchLoading() { return this.companySearchService.autocompleteSearchLoading; }

  get debtor_type(): FormControl { return this.form.get('debtor_type') as FormControl; }
  get name(): FormControl { return this.form.get('name') as FormControl; }
  get tax_number(): FormControl { return this.form.get('tax_number') as FormControl; }
  get country_iso(): FormControl { return this.form.get('address.country_iso') as FormControl; }
  get debtorPostcode(): FormControl { return this.form.get('address.postcode') as FormControl; }
  get debtorSettlement(): FormControl { return this.form.get('address.settlement') as FormControl; }
  get debtorStreet(): FormControl { return this.form.get('address.street') as FormControl; }

  constructor(
    private formService: FormService,
    private companySearchService: CompanySearchService,
  ) { }

  async ngOnInit(): Promise<void> {
    // this.name.valueChanges
    //   .pipe(
    //     takeUntil(this.destroy),
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //   )
    //   .subscribe({
    //     next: async name => {
    //       try {
    //         this.companySearchOptions = await this.companySearchService.getCompanySearchAutocompleteItems(name);
    //       } catch (error) {
    //         console.error('Error while company autocomplete', error);
    //       }
    //     }
    //   });

    this.country_iso.valueChanges
      .pipe(takeUntil(this.destroy), startWith(this.country_iso.value))
      .subscribe({
        next: (country: null | string | CountryItem) => {
          if (!country || typeof country === 'string') {
            this.debtorCountry.next(this.countryOptions.find(c => c.iso === country));
            return;
          }
          this.country_iso.patchValue(country.iso);
          this.debtorCountry.next(country);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async companySelected(event: MatAutocompleteSelectedEvent): Promise<void> {
    try {
      const company = event.option.value as CompanySearchAutocompleteItem;
      this.form.patchValue({
        name: company.name,
        tax_number: company.tax_number,
        address: {
          // @todo replace id with iso in DNB service
          // country_iso: company.country,
          postcode: company.postcode,
          settlement: company.settlement,
          street: company.street,
        },
      });

      const result = await this.companySearchService.detailedSearch(company.tax_number);

      if (!result) {
        console.warn('Company detailed search was null!');
        return;
      }

      this.form.patchValue({
        party_type_id: result.company.basicData.partyType?.internal_id,
        // @todo only use email
        email: result.company.contactData.value,
      });
    } catch (error) {
      console.error('Error while company detailed search', error);
    } finally {
      if (this.form.valid) {
        this.validCompanySelected.next();
      }
    }
  }

  countryDisplayWith(country?: CountryItem): string {
    return country?.name?.value ?? '';
  }

  companySearchDisplayWith(company?: CompanySearchAutocompleteItem): string {
    return company?.name ?? '';
  }
}
