<app-header-empty></app-header-empty>

<div *ngIf="isFirstClient" class="confetti-container">
  <img
    class="confetti"
    src="assets/shared/global-images/big-svgs/confetti.svg"
  />
</div>

<app-form-template>
  <ng-template #titleTemplate>
    <div class="title flex column vcenter">
      <img
        src="assets/shared/global-images/icons/checkmark-circle-green-big.svg"
      />
      <h2 color="grey-dark">
        {{ "ADD_NEW_CLIENT.SUCCESS.CONGRATULATIONS" | translate }}
      </h2>
      <h1>{{ clientName }}</h1>
    </div>
  </ng-template>

  <div class="description">
    {{ "ADD_NEW_CLIENT.SUCCESS.DESCRIPTION" | translate }}
  </div>

  <button payee-button width="wide" routerLink="/cases/wizard">
    {{ "ADD_NEW_CLIENT.SUCCESS.START_CASE" | translate }}
  </button>
  <button
    payee-button
    width="wide"
    color="white"
    [isBorderButton]="true"
    routerLink="/user/client-profile"
  >
    {{ "ADD_NEW_CLIENT.SUCCESS.DASHBOARD" | translate }}
  </button>
</app-form-template>
