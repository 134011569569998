<form [formGroup]="form">
  <div class="form-group-label top-margin-15">
    {{ "CASE_FORM.FORMS.DEBTOR.ORG.COMPANY_NAME_LABEL" | translate }}
  </div>
  <payee-form-field class="name-form-field">
    <input
      payee-input
      formControlName="name"
      [placeholder]="
        'CASE_FORM.FORMS.DEBTOR.ORG.COMPANY_NAME_PLACEHOLDER' | translate
      "
      [matAutocomplete]="auto"
    />
    <ng-container *ngIf="name.invalid && name.dirty" payee-error>
      <span *ngIf="name.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </span>
      <span *ngIf="name.hasError('maxlength')">
        {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
      </span>
    </ng-container>
    <span payee-suffix *ngIf="autocompleteSearchLoading">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="24"
      ></mat-progress-spinner>
    </span>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="companySelected($event)"
    >
      <mat-option *ngFor="let option of companySearchOptions" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </payee-form-field>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="tax_number"
        [placeholder]="
          'CASE_FORM.FORMS.DEBTOR.ORG.TAX_NUMBER_PLACEHOLDER' | translate
        "
      />
      <ng-container *ngIf="tax_number.invalid && tax_number.dirty" payee-error>
        <span *ngIf="tax_number.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="tax_number.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="form-group-label top-margin-15">
    {{ "CASE_FORM.FORMS.DEBTOR.ORG.CONTACT_DETAILS_LABEL" | translate }}
  </div>
  <form formGroupName="address">
    <div class="top-margin-15">
      {{ countryDisplayWith(debtorCountry.value) }}
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="postcode"
          [placeholder]="
            'CASE_FORM.FORMS.DEBTOR.ORG.POSTCODE_PLACEHOLDER' | translate
          "
        />
        <ng-container
          *ngIf="debtorPostcode.invalid && debtorPostcode.dirty"
          payee-error
        >
          <span *ngIf="debtorPostcode.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </span>
          <span *ngIf="debtorPostcode.hasError('maxlength')">
            {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="settlement"
          [placeholder]="
            'CASE_FORM.FORMS.DEBTOR.ORG.SETTLEMENT_PLACEHOLDER' | translate
          "
        />
        <ng-container
          *ngIf="debtorSettlement.invalid && debtorSettlement.dirty"
          payee-error
        >
          <span *ngIf="debtorSettlement.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </span>
          <span *ngIf="debtorSettlement.hasError('maxlength')">
            {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="street"
          [placeholder]="
            'CASE_FORM.FORMS.DEBTOR.ORG.STREET_PLACEHOLDER' | translate
          "
        />
        <ng-container
          *ngIf="debtorStreet.invalid && debtorStreet.dirty"
          payee-error
        >
          <span *ngIf="debtorStreet.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </span>
          <span *ngIf="debtorStreet.hasError('maxlength')">
            {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>
  </form>
</form>
