import moment, { Moment } from 'moment';
import { z } from 'zod';

export const momentSchema = z.custom<Moment>((value) => moment.isMoment(value), {
  message: "Value must be a moment instance",
});

export const debtorSchema = z.object({
  id: z.string().nullish(),
  jurisdiction: z.string().length(2),
  debtor_type: z.enum(['org', 'ind']),
  name: z.string().nullable(),
  party_type_id: z.string().nullish(),
  tax_number: z.string().nullish(),
  registration_number: z.string().nullish(),
  address: z.object({
    country_iso: z.string().length(2).nullable(),
    postcode: z.string().nullable(),
    settlement: z.string().nullable(),
    street: z.string().nullable(),
  }),
  representative: z.object({
    name: z.string().nullable(),
    address: z.object({
      country_iso: z.string().nullable(),
      postcode: z.string().nullable(),
      settlement: z.string().nullable(),
      street: z.string().nullable(),
    }),
  }).nullish(),
  is_editable: z.boolean(),
});
export type Debtor = z.infer<typeof debtorSchema>;

export const claimSchema = z.object({
  id: z.string(),
  file: z.object({
    file_name: z.string(),
    storage_url: z.string(),
  }).or(z.instanceof(File)).nullable(),
  original_amount: z.number(),
  due_date_at: z.string().or(momentSchema),
  is_editable: z.boolean().optional(),
  is_deletable: z.boolean().optional(),
});
export const claimsSchema = z.array(claimSchema);
export type Claim = z.infer<typeof claimSchema>;

export const clientSchema = z.object({
  id: z.string().nullish(),
  client_type: z.enum(['org', 'house']),
  name: z.string().nullable(),
  party_type_id: z.string().nullish(),
  tax_number: z.string().nullable(),
  registration_number: z.string().nullish(),
  address: z.object({
    country_iso: z.literal('HU').nullable(),
    // country_iso: z.string().nullable(),
    postcode: z.string().nullable(),
    settlement: z.string().nullable(),
    street: z.string().nullable(),
  }),
  birth_date: z.string().nullish(),
  birth_place: z.string().nullish(),
  mother_name: z.string().nullish(),
  representative: z.object({
    name: z.string().nullable(),
    address: z.object({
      country_iso: z.literal('HU').nullable(),
      // country_iso: z.string().nullable(),
      postcode: z.string().nullable(),
      settlement: z.string().nullable(),
      street: z.string().nullable(),
    }),
  }).nullish(),
});
export type Client = z.infer<typeof clientSchema>;

export const bankAccountSchema = z.object({
  id: z.string().nullish(),
  account_holder_name: z.string(),
  iban: z.string().nullable(),
  account_number: z.string().nullable(),
  // bic: z.string(),
});
export type BankAccount = z.infer<typeof bankAccountSchema>;

export type PaymentStepForm = 'debtor_form'
  | 'start_case_price_added_form'
  | 'interest_added_form'
  | 'flat_rate_cost_added_form';

export const productTypes = [
  'notice',
  'demand_letter',
  'hard_1',
  'hard_2',
] as const;
export type ProductType = typeof productTypes[number];
export type ProductFee = {
  label: string;
  details_text: string;
  amount: number;
  min: number | null;
  max: number | null;
  percentage: number | null;
};

export type Summary = {
  client: {
    name: string;
  };
  debtor: {
    name: string;
    email: string;
  };
  claims: {
    due_date_at: string;
    original_amount: number;
    currency_iso: string;
  }[];
  payee_case: {
    displayed_claims_sum_original_amount: number;
    claims_sum_original_amount: number;
    currency_iso: string;
    is_interest_added: boolean;
    interest_amount: number | null;
    is_flat_rate_cost_claim_added: boolean;
    flat_rate_cost_claims_sum: number | null;
    has_claims_eligible_for_flat_rate_cost: boolean;
    is_start_case_price_claim_added: boolean;
  };
  active_products: ProductType[];
  product: {
    currency_iso: string,
    is_product_type_editable: boolean,
    type: ProductType;
    hard_2_price: {
      min: number | null;
      max: number | null;
      amount: number | null;
    };
    forms: {
      notice: PaymentStepForm[];
      demand_letter: PaymentStepForm[];
      hard_1: PaymentStepForm[];
      hard_2: PaymentStepForm[];
    };
  };
  fees: {
    start: ProductFee[];
    'closed-success': ProductFee[];
  };
};

export const userEmailSchema = z.object({
  id: z.string().nullish(),
  email: z.string(),
});
export type UserEmail = z.infer<typeof userEmailSchema>;

// Szamlazz.hu
export const SZAMLAZZHU_INVALID_TYPE_ERROR = 'INVALID_TYPE';
export const SZAMLAZZHU_REMAINING_AMOUNT_MIN_ERROR = 'REMAINING_AMOUNT_MIN';
export const SZAMLAZZHU_REMAINING_AMOUNT_MAX_ERROR = 'REMAINING_AMOUNT_MAX';
export const SZAMLAZZHU_INVALID_CURRENCY_ERROR = 'INVALID_CURRENCY';
export const SZAMLAZZHU_DUE_DATE_AT_NOT_PAST_ERROR = 'DUE_DATE_AT_MAX';
export const SZAMLAZZHU_HAS_ONGOING_CASE_ERROR = 'HAS_ONGOING_CASE';
export const SZAMLAZZHU_UNKNOWN_ERROR = 'UNKNOWN';
export const szamlazzhuInvoice = z.object({
  invoice_id: z.string(),
  invoice_number: z.string(),
  due_date_at: z.string(),
  remaining_amount: z.number().int(),
  currency_iso: z.string(),
  total_remaining_amount: z.number().int(),
  file: z.object({
    file_name: z.string(),
    storage_url: z.string().url(),
  }).or(z.instanceof(File)).nullable(),
  corrections: z.array(z.object({
    invoice_number: z.string(),
    due_date_at: z.string(),
    remaining_amount: z.number().int(),
    currency_iso: z.string(),
  })),
  error_code: z.enum([
    SZAMLAZZHU_INVALID_TYPE_ERROR,
    SZAMLAZZHU_REMAINING_AMOUNT_MIN_ERROR,
    SZAMLAZZHU_REMAINING_AMOUNT_MAX_ERROR,
    SZAMLAZZHU_INVALID_CURRENCY_ERROR,
    SZAMLAZZHU_DUE_DATE_AT_NOT_PAST_ERROR,
    SZAMLAZZHU_HAS_ONGOING_CASE_ERROR,
    SZAMLAZZHU_UNKNOWN_ERROR,
  ]).nullable(),
});
export type SzamlazzhuInvoice = z.infer<typeof szamlazzhuInvoice>;

export const campaignSchema = z.object({
  utm_source: z.string().nullish(),
  utm_campaign: z.string().nullish(),
  utm_content: z.string().nullish(),
  utm_medium: z.string().nullish(),
});
export type Campaign = z.infer<typeof campaignSchema>;
