import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CountryItem, EnumService, PartyTypeItem } from 'src/app/services/enum/enum.service';
import { DEBTOR_FORM_NAME } from 'src/app/services/form/form.factory';
import { FormService } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-debtor-form',
  templateUrl: './debtor-form.component.html',
  styleUrls: ['./debtor-form.component.scss']
})
export class DebtorFormComponent implements OnInit, OnDestroy {
  get form(): FormGroup { return this.formService.form.get(DEBTOR_FORM_NAME) as FormGroup; }

  loading = true;
  partyTypeOptions: PartyTypeItem[] = [];
  countryOptions: CountryItem[] = [];
  jurisdictionOptions: string[] = [];

  collapsed: boolean = !this.form.invalid;

  get isEditable(): boolean { return !!this.form.get('is_editable')?.value || this.form.invalid; }
  get debtor_type(): FormControl { return this.form.get('debtor_type') as FormControl; }
  get name(): FormControl { return this.form.get('name') as FormControl; }
  get tax_number(): FormControl { return this.form.get('tax_number') as FormControl; }
  get jurisdiction(): FormControl { return this.form.get('jurisdiction') as FormControl; }

  get addressString(): string {
    const postcode = this.form.get('address.postcode').value ?? '';
    const settlement = this.form.get('address.settlement').value ?? '';
    const street = this.form.get('address.street').value ?? '';
    return `${postcode} ${settlement} ${street}`;
  }

  private readonly destroy = new Subject<void>();

  constructor(
    private formService: FormService,
    private enumService: EnumService,
    private translate: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      const [
        partyTypes,
        countries,
      ] = await Promise.all([
        this.enumService.getPartyTypes(),
        this.enumService.getCountries(),
      ]);
      this.partyTypeOptions = partyTypes;
      this.countryOptions = countries;
      this.jurisdictionOptions = countries.map(c => c.iso);
    } catch (error) {
      console.error('Error while loading enums', error);
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  readonly countryDisplayWith = (iso: string): string => {
    return this.translate.instant(`COUNTRY_NAMES.${iso}`);
  };
}
