import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-add-new-client-onboarding',
  templateUrl: './add-new-client-onboarding.component.html',
  styleUrls: ['./add-new-client-onboarding.component.scss']
})
export class AddNewClientOnboardingComponent implements OnInit, OnDestroy {
  private readonly destroy = new Subject<void>();

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.translate.stream('BROWSER_TITLES.ADD_NEW_CLIENT_ONBOARDING')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async logout() {
    await this.authService.logout();
  }
}
