import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import axios from 'axios';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormFactory, SZAMLAZZHU_INVOICES_FORM_NAME } from 'src/app/services/form/form.factory';
import { SzamlazzhuInvoice } from 'src/app/services/form/form.resources';
import { FormService } from 'src/app/services/form/form.service';
import { GetKsziInfoResponse, SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { environment } from 'src/environments/environment';

type InvoiceSelector = {
  selected: boolean;
  file: SzamlazzhuInvoice['file'] | null;
  index: number | null;
};

@Component({
  selector: 'app-szamlazzhu-invoices-form',
  templateUrl: './szamlazzhu-invoices-form.component.html',
  styleUrls: ['./szamlazzhu-invoices-form.component.scss']
})
export class SzamlazzhuInvoicesFormComponent implements OnInit, OnDestroy {
  private invoicesLoadingCounter = 0;
  get invoicesLoading(): boolean { return this.invoicesLoadingCounter > 0 || this.formService.saving.value > 0; }

  get form(): FormArray { return this.formService.form.get(SZAMLAZZHU_INVOICES_FORM_NAME) as FormArray; }

  /**
   * Hack to force filterPredicate to run even when no invoiceFilter is empty
   */
  private readonly defaultFilter = 'WUxdUEFVsOTxGtAJ';
  readonly hideInvalidInvoices: FormControl;
  readonly invoiceFilter: FormControl;
  readonly invoiceSelectorForm: FormGroup;

  get hasMultipleInvoices(): boolean { return this.dataSource.data.length > 1; }

  private ksziInfo?: GetKsziInfoResponse;
  private ksziInterval?: NodeJS.Timeout;
  get ksziSyncInProgress(): boolean { return this.ksziInfo?.invoices_pending > 0; }
  get ksziEstimatedFinishTime(): Date | null {
    if (!this.ksziInfo) {
      return null;
    }

    return moment().add(this.ksziInfo.minutes_left, 'minutes').toDate();
  }

  readonly dataSource = new MatTableDataSource<SzamlazzhuInvoice>();
  readonly displayedColumns = [
    'selected',
    'invoice_number',
    'due_date_at',
    'remaining_amount',
    'file',
  ] as const;

  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (this.dataSource.sort === sort) {
      return;
    }
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  private readonly destroy = new Subject<void>();

  constructor(
    private formService: FormService,
    private szamlazzhuService: SzamlazzhuService,
    private fb: FormBuilder,
  ) {
    this.dataSource.sortingDataAccessor = (data, headerId: typeof this.displayedColumns[number]): string | number => {
      switch (headerId) {
        case 'file': return !data.file ? 0 : data.file instanceof File ? 1 : 2;
        case 'remaining_amount': return data.total_remaining_amount;
        default: return data[headerId];
      }
    };

    this.dataSource.filterPredicate = (data, filter) => {
      if (this.hideInvalidInvoices.value && data.error_code) {
        return false;
      }
      if (this.defaultFilter === filter) {
        return true;
      }
      return data.invoice_number.includes(filter) || data.corrections.some(i => i.invoice_number.includes(filter));
    };
    this.dataSource.filter = this.defaultFilter;

    this.invoiceSelectorForm = this.fb.group({});
    this.hideInvalidInvoices = this.fb.control(true);
    this.invoiceFilter = this.fb.control('');
  }

  async ngOnInit(): Promise<void> {
    this.setInvoicesFromForm();
    this.ksziInfo = await this.szamlazzhuService.getKsziInfo();
    await this.getInvoices();
    this.scheduleKsziInfo();

    this.formService.saving.subscribe({
      next: async (s) => {
        if (s <= 0) {
          await this.getInvoices();
        }
      }
    });

    this.hideInvalidInvoices.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.dataSource.filter = this.dataSource.filter;
      });

    this.invoiceFilter.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        if (value) {
          this.dataSource.filter = value;
        } else {
          this.dataSource.filter = this.defaultFilter;
        }
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.ksziInterval);
    this.destroy.next();
    this.destroy.complete();
  }

  getInvoiceSelector(invoice: SzamlazzhuInvoice): undefined | FormGroup {
    return this.invoiceSelectorForm.controls[invoice.invoice_id] as FormGroup;
  }

  async toggleInvoice(invoice: SzamlazzhuInvoice): Promise<void> {
    if (this.invoicesLoading) {
      console.warn("Invoice toggle not allowed - loading");
      return;
    }

    const invoiceForm = this.getInvoiceSelector(invoice);
    if (!invoiceForm) {
      console.warn('Invoice form not found');
      return;
    }

    const value = invoiceForm.value as InvoiceSelector;

    if (!value.selected) {
      if (!value.file) {
        try {
          invoice.file = await this.getInvoiceFile(invoice);
          invoiceForm.patchValue({
            file: invoice.file,
          });
        } catch (error) {
          console.error('Error while getting invoice file', error);
        }
      }

      const form = new FormFactory(this.formService, this.fb, invoice).getForm('szamlazzhu-invoice');
      this.form.push(form);
      invoiceForm.patchValue({
        index: this.form.length - 1,
        selected: true,
      });
    } else {
      this.form.removeAt(value.index);
      invoiceForm.patchValue({
        index: null,
        selected: false,
      });
    }
    this.form.markAsDirty();
  }

  uploadInvoice(event: Event, invoice: SzamlazzhuInvoice): void {
    if (invoice.file && !(invoice.file instanceof File)) {
      console.warn("Can't replace already uploaded file");
      return;
    }

    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (files.length <= 0) {
      return;
    }

    const file = files.item(0);
    invoice.file = file;
    inputElement.value = '';

    const invoiceForm = this.getInvoiceSelector(invoice);
    if (!invoiceForm) {
      return;
    }

    invoiceForm.patchValue({
      file,
    });
    const value = invoiceForm.value as InvoiceSelector;
    this.form.at(value.index)?.patchValue({
      file,
    });
  }

  removePendingFile(event: Event, invoice: SzamlazzhuInvoice): void {
    event.stopPropagation();

    invoice.file = null;
    const invoiceForm = this.getInvoiceSelector(invoice);
    if (!invoiceForm) {
      return;
    }

    invoiceForm.patchValue({
      file: null,
    });

    const value = invoiceForm.value as InvoiceSelector;
    this.form.at(value.index)?.patchValue({
      file: null,
    });
  }

  // Set datasource from the already selected invoices
  private setInvoicesFromForm(): void {
    const formData = this.formService.forms.find(f => f.name === SZAMLAZZHU_INVOICES_FORM_NAME);
    if (formData) {
      this.dataSource.data = formData.data as SzamlazzhuInvoice[];
      for (let i = 0; i < this.dataSource.data.length; ++i) {
        const invoice = this.dataSource.data[i];
        let invoiceForm = this.getInvoiceSelector(invoice);
        if (!invoiceForm) {
          invoiceForm = this.fb.group({
            selected: true,
            file: invoice.file,
            index: i,
          });

          this.invoiceSelectorForm.setControl(invoice.invoice_id, invoiceForm);
        } else {
          invoiceForm.patchValue({
            index: i,
            selected: true,
            file: invoice.file,
          });
        }
      }
    }
  }

  private async getInvoices(): Promise<void> {
    try {
      ++this.invoicesLoadingCounter;
      const url = `${environment.baseUrl}/api/case-form/${this.formService.caseId}/szamlazzhu/invoice`;
      const result = await axios.get<{ invoices: SzamlazzhuInvoice[]; }>(url);
      /**
       * We assign the invoices in this order to prevent the rows from jumping around.
       * 
       * 1. Add invoices that were already selected
       * 2. Get all available invoices
       *    - If the invoice is new add it to the end of the list
       *    - If the invoice is already added update its contents
       */
      const invoices = this.dataSource.data;
      for (const invoice of result.data.invoices) {
        let invoiceForm = this.getInvoiceSelector(invoice);
        if (!invoiceForm) {
          invoiceForm = this.fb.group({
            selected: false,
            file: invoice.file,
            index: null,
          });

          this.invoiceSelectorForm.setControl(invoice.invoice_id, invoiceForm);
        } else {
          invoiceForm.patchValue({
            file: invoice.file,
          });
        }

        if (invoice.error_code) {
          invoiceForm.disable();
        }

        const index = invoices.findIndex(i => i.invoice_id === invoice.invoice_id);
        if (index === -1) {
          invoices.push(invoice);
        } else {
          invoices[index] = invoice;
        }
      }
      this.dataSource.data = invoices;
    } catch (error) {
      console.error('Error while getting szamlazzhu invoices', error);
    } finally {
      --this.invoicesLoadingCounter;
    }
  }

  private async getInvoiceFile(invoice: SzamlazzhuInvoice): Promise<SzamlazzhuInvoice['file']> {
    try {
      ++this.invoicesLoadingCounter;
      const url = `${environment.baseUrl}/api/case-form/${this.formService.caseId}/szamlazzhu/invoice-file/${invoice.invoice_id}`;
      const result = await axios.get<{ file: SzamlazzhuInvoice['file']; }>(url);
      return result.data.file;
    } catch (error) {
      console.error('Error while getting szamlazzhu invoice file', error);
    } finally {
      --this.invoicesLoadingCounter;
    }
  }

  private scheduleKsziInfo(): void {
    this.ksziInterval = setInterval(
      async () => {
        try {
          const oldLoading = this.ksziInfo?.invoices_pending ?? 0;
          const oldInvoicesRecieved = this.ksziInfo?.invoices_recieved ?? 0;
          this.ksziInfo = await this.szamlazzhuService.getKsziInfo();
          if (
            this.ksziInfo?.invoices_pending < oldLoading
            || this.ksziInfo?.invoices_recieved < oldInvoicesRecieved
          ) {
            await this.getInvoices();
          }
        } catch (error) {
          console.error('Error while getting kszi info', error);
        }
      },
      // Poll every minute
      10_000,
    );
  }
}
