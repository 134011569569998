<h2>{{ 'CASE_FORM.FORMS.CLIENT.TITLE' | translate }}</h2>
<section
  *ngIf="!collapsed && !loading"
  class="forms"
  [class.valid]="form.valid"
>
  <div class="form-group-label top-margin-15">
    {{ 'CASE_FORM.FORMS.CLIENT.TYPE_LABEL' | translate }}
  </div>
  <div class="flex vcenter top-margin-5 bottom-margin-15">
    <div class="flex vcenter grow1 type-selector">
      <button
        payee-button
        class="option"
        [class.active]="client_type.value === 'org'"
        (click)="client_type.patchValue('org')"
      >
        <div class="flex vcenter" style="gap: 10px">
          <span>{{ 'CASE_FORM.FORMS.CLIENT.TYPE_SELECTOR.ORG' | translate }}</span>
        </div>
      </button>
      <button
        payee-button
        class="option"
        [class.active]="client_type.value === 'house'"
        (click)="client_type.patchValue('house')"
      >
        <div class="flex vcenter" style="gap: 10px">
          <span>{{ 'CASE_FORM.FORMS.CLIENT.TYPE_SELECTOR.HOUSE' | translate }}</span>
        </div>
      </button>
    </div>
    <button
      *ngIf="form.valid"
      payee-icon-button
      color="white"
      (click)="collapsed = true"
    >
      <payee-icon name="collapse"></payee-icon>
    </button>
  </div>

  <app-client-org-form
    *ngIf="client_type.value === 'org'"
    [countryOptions]="countryOptions"
    (validCompanySelected)="collapsed = true"
  ></app-client-org-form>
  <app-client-house-form
    *ngIf="client_type.value === 'house'"
    [countryOptions]="countryOptions"
  ></app-client-house-form>
</section>

<div
  *ngIf="collapsed"
  class="client-summary flex vcenter top-margin-15"
  (click)="collapsed = false"
>
  <payee-icon name="checkmark-darker-green-circle"></payee-icon>
  <div class="client-data flex column grow1">
    <div class="client-name">{{ form.value.name }}</div>
    <div class="client-address">{{ addressString }}</div>
    <div *ngIf="form.value.tax_number" class="client-tax-number">
      {{ form.value.tax_number }}
    </div>
  </div>
  <payee-icon name="expand"></payee-icon>
</div>
