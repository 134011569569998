import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { CreateClientDialogComponent } from 'src/app/dialogs/client/create-client-dialog/create-client-dialog.component';
import { WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { environment } from 'src/environments/environment';
import { Client } from 'src/types';
import { BankAccountStepData } from '../../aa-new-form/form-steps/init-step/models/bank-account/bank-account-step-data';
import { CreateClientDto } from '../../dtos/create-client.dto';
import { User, UsersService } from '../users/users.service';
import { TranslateService } from '@ngx-translate/core';

export type UpdateBankAccountParams = {
  account_holder_name?: string;
  account_number?: string;
  iban?: string;
  bank_name?: string;
};

export type UpdateClientParams = {
  default_tone_of_voice?: 'formal' | 'informal';
};

export type SelectedClient = null | {
  id: Client['uuid'];
  bankAccount: {
    account_holder_name: string | null;
    account_number: string | null;
    bank_name: string | null;
    bic: string | null;
    created_at: string | null;
    iban: string | null;
    id: number;
    model_id: number;
    model_type: string;
    updated_at: string | null;
  };
  draft_cases: number;
  ongoing_cases: number;
  closed_cases: number;
  ask_for_tax_number: boolean;
  country_iso: string | null;
} & Pick<Client, 'name' | 'default_tone_of_voice' | 'tax_number'>;

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private user?: any;

  private get selectedClient(): SelectedClient | null { return this.selectedClientSubject.value; };
  get selectedClientId(): string { return this.selectedClient?.id; }
  get selectedClientIdLocalStoragePath() {
    return this.user ? `selectedClientId/${this.user.email}` : null;
  }

  // tslint:disable-next-line
  private _createClientLoading = false;
  get createClientLoading() { return this._createClientLoading; }

  readonly clientsSubject = new BehaviorSubject<SelectedClient[] | null>(null);
  readonly selectedClientSubject = new BehaviorSubject<SelectedClient | null>(null);

  constructor(
    private usersService: UsersService,
    private workflowService: WorkflowService,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.usersService.userSubject.subscribe({
      next: user => this.setUser(user),
    });

    this.selectedClientSubject.subscribe({
      next: () => this.saveSelectedClientIdToLocalStorage(),
    });

    this.workflowService.afterWorkflow.subscribe({
      next: () => this.setClients(),
    });
  }

  async createNewClient(createClientDto: CreateClientDto): Promise<void> {
    const userId = this.usersService.userSubject.getValue().id;
    const url = `${environment.baseUrl}/api/user/${userId}/client`;

    const { data } = await axios.post(url, createClientDto);
    const currentClientsArray = this.clientsSubject.getValue();
    currentClientsArray.push(data.client);
    this.clientsSubject.next(currentClientsArray);
    this.setSelectedClient(data.client);
  }

  setSelectedClient(client?: SelectedClient): void {
    this.selectedClientSubject.next(client);
    this.saveSelectedClientIdToLocalStorage();
  }

  async getClient(clientId: string = this.selectedClient?.id): Promise<SelectedClient> {
    return await new Promise<SelectedClient>(resolve => this.clientsSubject
      .pipe(filter(value => value.length > 0), first())
      .subscribe({
        next: clients => {
          resolve(clients.find(c => c.id === clientId));
        },
      }));
  }

  async getSelectedClient(): Promise<SelectedClient> {
    return await new Promise<SelectedClient>(resolve => this.selectedClientSubject
      .pipe(filter(v => !!v), first())
      .subscribe({
        next: client => resolve(client),
      }));
  }

  async updateDefaultToneOfVoice(newToneOfVoice: "formal" | "informal"): Promise<void> {
    const currentClient = this.selectedClient;
    if (currentClient == null) {
      return;
    }

    const updatedClient: SelectedClient = {
      ...currentClient,
      default_tone_of_voice: newToneOfVoice
    };

    this.selectedClientSubject.next(updatedClient);
    await this.updateClient({
      default_tone_of_voice: newToneOfVoice,
    });
  }

  async updateClient(params: UpdateClientParams, clientId = this.selectedClientId): Promise<void> {
    if (!clientId) {
      return;
    }

    this.clientsSubject;
    const url = `${environment.baseUrl}/api/client/${clientId}`;
    await axios.put(url, params);
  }

  getClientName(client: SelectedClient): string {
    return client?.name || '';
  }

  async updateBankAccount(params: UpdateBankAccountParams): Promise<BankAccountStepData> {
    const clientId = this.selectedClientSubject.getValue().id;
    const url = `${environment.baseUrl}/api/client/${clientId}/bank-account`;
    const { data } = await axios.put(url, params);
    await this.setClients();
    return data.bank_account_step;
  }

  async setClients(): Promise<void> {
    const url = environment.baseUrl + `/api/client`;
    const response = await axios.get<SelectedClient[]>(url);

    const clients = response.data;
    this.clientsSubject.next(clients);

    const selectedClientId = this.getSelectedClientIdFromLocalStorage();
    const cachedClient = clients.find(c => c.id as any === selectedClientId);
    if (cachedClient) {
      this.setSelectedClient(cachedClient);
    } else if (clients.length > 0) {
      this.setSelectedClient(clients[0]);
    } else if (this.router.url !== '/user/add-new-client') {
      this.router.navigateByUrl('user/add-new-client-onboarding');
    }
  }

  private async setUser(user?: User): Promise<void> {
    const isNewUser = user?.email !== this.user?.email;
    this.user = user;

    // logout
    if (!user) {
      return;
    }

    if (isNewUser) {
      await this.setClients();
    }
  }

  private getSelectedClientIdFromLocalStorage(): string | null {
    if (!this.selectedClientIdLocalStoragePath) {
      return null;
    }
    return localStorage.getItem(this.selectedClientIdLocalStoragePath);
  }

  private saveSelectedClientIdToLocalStorage(id = this.selectedClient?.id): void {
    if (!this.selectedClientIdLocalStoragePath || !id) {
      return;
    }
    localStorage.setItem(this.selectedClientIdLocalStoragePath, id);
  }

  private openCreateClientDialog() {
    this.dialog.open(CreateClientDialogComponent, {
      disableClose: true,
    });
  }
}
