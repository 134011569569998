<app-header-empty>
  <ng-template #buttonTemplate>
    <button payee-icon-button (click)="logout()" color="white">
      <img src="assets/shared/global-images/icon-set/logout-grey.svg" />
    </button>
  </ng-template>
</app-header-empty>

<div class="confetti-container">
  <img
    class="confetti"
    src="assets/shared/global-images/big-svgs/confetti.svg"
  />
</div>

<app-form-template>
  <ng-template #titleTemplate>
    <div class="title flex column vcenter">
      <img
        src="assets/shared/global-images/icons/plus-circle.svg"
        width="114px"
        height="114px"
      />
      <h1>{{ "ADD_NEW_CLIENT.ONBOARDING.WELCOME_TITLE" | translate }}</h1>
    </div>
  </ng-template>

  <div class="description">
    {{ "ADD_NEW_CLIENT.ONBOARDING.DESCRIPTION" | translate }}
  </div>

  <ul class="flex column info-list">
    <li class="flex vcenter">
      <img
        src="assets/shared/global-images/icons/checkmark-circle-green.svg"
        width="20px"
        height="20px"
      />
      &nbsp; {{ "ADD_NEW_CLIENT.ONBOARDING.LIST_ITEM_1" | translate }}
    </li>
    <li class="flex vcenter">
      <img
        src="assets/shared/global-images/icons/checkmark-circle-green.svg"
        width="20px"
        height="20px"
      />
      &nbsp; {{ "ADD_NEW_CLIENT.ONBOARDING.LIST_ITEM_2" | translate }}
    </li>
    <li class="flex vcenter">
      <img
        src="assets/shared/global-images/icons/checkmark-circle-green.svg"
        width="20px"
        height="20px"
      />
      &nbsp; {{ "ADD_NEW_CLIENT.ONBOARDING.LIST_ITEM_3" | translate }}
    </li>
  </ul>

  <button payee-button width="wide" routerLink="/user/add-new-client">
    {{ "ADD_NEW_CLIENT.ONBOARDING.BUTTON_TEXT" | translate }}
  </button>
</app-form-template>
