import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { ClientsService } from '../clients/clients.service';
import { FormTypeEnum } from 'src/app/aa-new-form/form-steps/init-step/models/common/form-type-enum';
import { TranslateService } from '@ngx-translate/core';

export type GetProductPricesParams = {
  partner?: string;
}

export type GetProductPricesResponse = {
  soft_fix: number;
  soft_success: number;
  demand_letter_fix: number;
  hard_fix: number;
  execution_fix: number;
  execution_fix_min: number | null;
  execution_fix_max: number | null;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  readonly productLabels: Record<FormTypeEnum, string> = {
    soft: this.translate.instant('PRODUCT_NAMES.SOFT'),
    demand_letter: this.translate.instant('PRODUCT_NAMES.DEMAND_LETTER'),
    hard: this.translate.instant('PRODUCT_NAMES.HARD_1'),
  };

  constructor(
    private clientsService: ClientsService,
    private translate: TranslateService,
  ) {}

  async getProductPrices(
    params: GetProductPricesParams = {},
    clientId = this.clientsService.selectedClientId,
  ): Promise<GetProductPricesResponse> {
    const url = `${environment.baseUrl}/api/product/${clientId}/prices`;
    const result = await axios.get<GetProductPricesResponse>(url, { params });
    return result.data;
  }
}
