import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ClientsService, SelectedClient } from 'src/app/services/clients/clients.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-new-client-success',
  templateUrl: './add-new-client-success.component.html',
  styleUrls: ['./add-new-client-success.component.scss']
})
export class AddNewClientSuccessComponent implements OnInit, OnDestroy {
  isFirstClient?: boolean;

  private readonly destroy = new Subject<void>();
  get client(): SelectedClient | undefined { return this.clientsService.selectedClientSubject.value; }
  get clientName() { return this.client?.name; }

  constructor(
    private titleService: Title,
    private clientsService: ClientsService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.translate.stream('BROWSER_TITLES.ADD_NEW_CLIENT_SUCCESS')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });

    this.clientsService.clientsSubject
      .pipe(
        takeUntil(this.destroy),
        first(),
      )
      .subscribe(clients => {
        this.isFirstClient = clients.length === 1;
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
