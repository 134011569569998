import { AbstractControl, FormBuilder } from "@angular/forms";
import { FormService } from "./form.service";
import { getClaimForm } from "./forms/claim";
import { getClaimsForm } from "./forms/claims";
import { getClientForm } from "./forms/client";
import { getHuDebtorForm } from "./forms/hu-debtor";
import { getSummaryForm } from "./forms/summary";
import { BankAccount, Campaign, Claim, Client, Debtor, Summary, SzamlazzhuInvoice, UserEmail } from "./form.resources";
import { getUserEmailForm } from "./forms/user-email";
import { getSzamlazzhuInvoicesForm } from "./forms/szamlazzhu-invoices";
import { getSzamlazzhuInvoiceForm } from "./forms/szamlazzhu-invoice";
import { getDebtorForm } from "./forms/debtor";
import { getHuClientForm } from "./forms/hu-client";
import { getBankAccountForm } from "./forms/bank-account";

export const BANK_ACCOUNT_FORM_NAME = 'bank-account' as const;
export const CAMPAIGN_FORM_NAME = 'campaign' as const;
export const CLAIM_FORM_NAME = 'claim' as const;
export const CLAIMS_FORM_NAME = 'claims' as const;
export const CLIENT_FORM_NAME = 'client' as const;
export const DEBTOR_FORM_NAME = 'debtor' as const;
export const HU_CLIENT_FORM_NAME = 'hu-client' as const;
export const HU_DEBTOR_FORM_NAME = 'hu-debtor' as const;
export const SUMMARY_FORM_NAME = 'summary' as const;
export const SZAMLAZZHU_INVOICE_FORM_NAME = 'szamlazzhu-invoice' as const;
export const SZAMLAZZHU_INVOICES_FORM_NAME = 'szamlazzhu-invoices' as const;
export const USER_FORM_NAME = 'user' as const;

export type Form = {
  // GLOBAL
  [USER_FORM_NAME]: UserEmail;

  // Default
  [DEBTOR_FORM_NAME]: Debtor;
  [CLAIMS_FORM_NAME]: Claim[];
  [CLAIM_FORM_NAME]: Claim;
  [CLIENT_FORM_NAME]: Client;
  [BANK_ACCOUNT_FORM_NAME]: BankAccount;
  [SUMMARY_FORM_NAME]: Summary;

  // HU
  [HU_DEBTOR_FORM_NAME]: Debtor;
  [HU_CLIENT_FORM_NAME]: Client;

  // Szamlazz.hu
  [SZAMLAZZHU_INVOICES_FORM_NAME]: SzamlazzhuInvoice[],
  [SZAMLAZZHU_INVOICE_FORM_NAME]: SzamlazzhuInvoice,

  // Helper
  [CAMPAIGN_FORM_NAME]: Campaign,
};

export const formNames: FormName[] = [
  BANK_ACCOUNT_FORM_NAME,
  CAMPAIGN_FORM_NAME,
  CLAIMS_FORM_NAME,
  CLIENT_FORM_NAME,
  DEBTOR_FORM_NAME,
  HU_CLIENT_FORM_NAME,
  HU_DEBTOR_FORM_NAME,
  SUMMARY_FORM_NAME,
  SZAMLAZZHU_INVOICE_FORM_NAME,
  SZAMLAZZHU_INVOICES_FORM_NAME,
  USER_FORM_NAME,
];
export type FormName = keyof Form;

export class FormFactory {
  constructor(
    protected formService: FormService,
    protected fb: FormBuilder,
    protected data?: Form[FormName],
  ) { }

  getForm(step: FormName): AbstractControl {
    switch (step) {
      // GLOBAL
      case 'user': return this.getUserEmailForm();
      // Default
      case 'debtor': return this.getDebtorForm();
      case 'claims': return this.getClaimsForm();
      case 'claim': return this.getClaimForm();
      case 'client': return this.getClientForm();
      case 'bank-account': return this.getBankAccountForm();
      case 'summary': return this.getSummaryForm();
      // HU
      case 'hu-debtor': return this.getHuDebtorForm();
      case 'hu-client': return this.getHuClientForm();
      // Szamlazz.hu
      case 'szamlazzhu-invoice': return this.getSzamlazzhuInvoiceForm(this.data);
      case 'szamlazzhu-invoices': return this.getSzamlazzhuInvoicesForm();
      default: throw new Error(`Unknown step ${step}`);
    }
  }

  // Global
  protected readonly getUserEmailForm = getUserEmailForm.bind(this);
  // Default
  protected readonly getDebtorForm = getDebtorForm.bind(this);
  protected readonly getClaimsForm = getClaimsForm.bind(this);
  protected readonly getClaimForm = getClaimForm.bind(this);
  protected readonly getClientForm = getClientForm.bind(this);
  protected readonly getBankAccountForm = getBankAccountForm.bind(this);
  protected readonly getSummaryForm = getSummaryForm.bind(this);
  // HU
  protected readonly getHuDebtorForm = getHuDebtorForm.bind(this);
  protected readonly getHuClientForm = getHuClientForm.bind(this);

  // Szamlazz.hu
  protected readonly getSzamlazzhuInvoicesForm = getSzamlazzhuInvoicesForm.bind(this);
  protected readonly getSzamlazzhuInvoiceForm = getSzamlazzhuInvoiceForm.bind(this);
}