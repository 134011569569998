<payee-header>
  <ng-template #logoTemplate>
    <a [href]="payeeLink">
      <img
        src="assets/shared/global-images/payee-logos/v1/black-payee-blue-dot.svg"
        width="100px"
      />
    </a>
  </ng-template>
  <ng-template #payeeHeaderLogoTemplate>
    <ng-container
      [ngTemplateOutlet]="logoTemplateRef || logoTemplate"
    ></ng-container>
  </ng-template>
  <ng-content></ng-content>
  <ng-template #payeeHeaderButtonTemplate>
    <div class="buttons flex vcenter">
      <button
        *ngIf="isAuthenticated"
        payee-icon-button
        [isBorderButton]="true"
        color="white"
        class="logout"
        (click)="logout()"
      >
        <payee-icon name="logout-grey"></payee-icon>
      </button>
      <a
        payee-header-button
        class="flex"
        (click)="onXClicked()"
        [routerLink]="xLink"
      >
        <img
          src="assets/shared/global-images/icon-set/cross-black.svg"
          alt="X"
        />
      </a>
    </div>
  </ng-template>
</payee-header>
