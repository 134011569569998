<h2>{{ "CASE_FORM.FORMS.CLAIMS.TITLE" | translate }}</h2>
<section class="forms flex column">
  <span *ngIf="form.dirty && form.invalid" color="alert-red">
    <ng-container *ngIf="form.hasError('max')">
      {{
        "CASE_FORM.FORMS.CLAIMS.ERRORS.MAX_AMOUNT"
          | translate
            : {
                max: form.errors.max.max | number : "1.0" : "hu",
                currency: currency_iso
              }
      }}
    </ng-container>
  </span>
  <app-claim-form
    *ngFor="let claimForm of filteredClaims | keyvalue"
    [form]="claimForm.value"
    [index]="+claimForm.key + pageIndex * pageSize"
  ></app-claim-form>
  <span *ngIf="form.dirty && form.invalid" color="alert-red">
    <ng-container *ngIf="form.hasError('max')">
      {{
        "CASE_FORM.FORMS.CLAIMS.ERRORS.MAX_AMOUNT"
          | translate
            : {
                max: form.errors.max.max | number : "1.0" : "hu",
                currency: currency_iso
              }
      }}
    </ng-container>
  </span>
  <mat-paginator
    *ngIf="form.length > 5"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [length]="form.length"
    (page)="changePage($event)"
    [pageSizeOptions]="[1, 5, 10]"
  ></mat-paginator>
</section>
<button
  *ngIf="isEditable"
  payee-button
  color="white"
  class="top-margin-15 new-claim-button"
  (click)="addClaim()"
>
  <div class="flex vcenter">
    <payee-icon name="plus-blue"></payee-icon>
    <span color="blue-king">{{
      "CASE_FORM.FORMS.CLAIMS.ADD_CLAIM_BUTTON" | translate
    }}</span>
  </div>
</button>
