import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { DebtorFormComponent } from './debtor-form.component';
import { DebtorIndFormComponent } from './debtor-ind-form/debtor-ind-form.component';
import { DebtorOrgFormComponent } from './debtor-org-form/debtor-org-form.component';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    DebtorFormComponent,
    DebtorIndFormComponent,
    DebtorOrgFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    PayeeButtonsModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    ReactiveFormsModule,
    TranslateModule,
  ]
})
export class DebtorFormModule { }
