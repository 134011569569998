import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Claim } from 'src/app/services/form/form.resources';
import { FormService } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-claim-form[form][index]',
  templateUrl: './claim-form.component.html',
  styleUrls: ['./claim-form.component.scss']
})
export class ClaimFormComponent {
  @Input() form: FormGroup;
  @Input() index: number;

  readonly maxDueDate = new Date(Date.now() - 24 * 60 * 60 * 1000);

  get claimsForm(): FormArray { return this.form.parent as FormArray; }
  get value(): Claim { return this.form.value; }

  get isFileUploaded(): boolean {
    return this.file.value && !(this.file.value instanceof File);
  }
  get file(): FormControl { return this.form?.get('file') as FormControl; }
  get original_amount(): FormControl { return this.form?.get('original_amount') as FormControl; }
  get due_date_at(): FormControl { return this.form?.get('due_date_at') as FormControl; }

  get isDeleteable(): boolean {
    return this.value.is_deletable && this.claimsForm.length > 1;
  }
  get isEditable(): boolean {
    return this.value.is_editable;
  }
  get currency_iso(): string { return this.formService.case.currency_iso; }

  constructor(
    private formService: FormService,
  ) {
  }

  fileDropped(files: FileList) {
    const file = files.item(0);
    if (file) {
      this.file.setValue(file);
      this.file.markAsDirty();
    }
  }

  async deleteClaim(): Promise<void> {
    const id = this.form.value.id;
    try {
      if (id) {
        await this.formService.deleteClaim(id);
      }
      this.claimsForm.removeAt(this.index);
    } catch (error) {
      console.error('Error while deleting claim', error);
    }
  }
}
