import { AbstractControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { AxiosError } from 'axios';
import * as ibantools from 'ibantools';
import { debounceTime, filter } from "rxjs/operators";
import { errorResponse } from 'src/types/error-response';
import { FormFactory } from "../form.factory";
import { BankAccount } from "../form.resources";

function getBBANCountryCode(form?: AbstractControl): string {
  const clientCountryPaths = [
    'client.address.country_iso',
    'hu-client.address.country_iso',
  ];
  const countryCode = clientCountryPaths.reduce(
    (code, path): string => form?.get(path)?.value || code,
    'HU',
  );
  return countryCode;
}

export function getBankAccountForm(this: FormFactory): FormGroup {
  const data = this.data as BankAccount;

  const form = this.fb.group({
    id: data?.id ?? (this.formService.isGuest ? new Date().valueOf().toString() : null),
    account_holder_name: [data.account_holder_name, [Validators.required, Validators.maxLength(255)]],
    account_number: [data.iban || data.account_number, [Validators.required, Validators.maxLength(255), bankAccountFormatValidator]],
    // bic: [data.bic, [Validators.required, Validators.maxLength(255)]],
  });

  if (data.account_number) {
    form.get('account_number').markAsDirty();
  }

  form.statusChanges
    .pipe(
      debounceTime(1000),
      filter(() => form.valid),
    )
    .subscribe({
      next: async () => {
        try {
          const value = form.value as Omit<BankAccount, 'iban'>;

          const electronicFormatted = ibantools.electronicFormatIBAN(value.account_number);
          const iban = ibantools.isValidIBAN(electronicFormatted) ? electronicFormatted : null;

          const bban = ibantools.isValidBBAN(
            electronicFormatted,
            getBBANCountryCode(this.formService.form),
          ) ? electronicFormatted : null;

          const params: BankAccount = {
            ...value,
            iban,
            account_number: bban,
          };

          await this.formService.upsertBankAccount(params);
        } catch (error) {
          console.error('Error while saving bank account', error);
          if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
              const errorData = errorResponse.safeParse(error.response?.data);
              if (errorData.success) {
                Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                  form.get(path).setErrors({
                    unknown: errors[0],
                  });
                });
              }
            }
          }
        }
      }
    });

  return form;
}

function bankAccountFormatValidator(control: AbstractControl): ReturnType<ValidatorFn> {
  const value = control.value;
  if (!value) {
    return null;
  }

  const electronicFormatted = ibantools.electronicFormatIBAN(value);
  const countryCode = getBBANCountryCode(control.parent?.parent);
  if (
    ibantools.isValidIBAN(electronicFormatted)
    || ibantools.isValidBBAN(electronicFormatted, countryCode)
  ) {
    return null;
  }

  return {
    pattern: true,
  };
}