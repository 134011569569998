import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-header-empty',
  templateUrl: './header-empty.component.html',
  styleUrls: ['./header-empty.component.scss']
})
export class HeaderEmptyComponent {
  @Input() payeeLink = environment.HELP_URL; // 'https://payee.tech';

  @ContentChild('logoTemplate') logoTemplateRef: TemplateRef<any>;
  @ContentChild('buttonTemplate') buttonTemplateRef: TemplateRef<any>;

  get isAuthenticated(): boolean { return this.authService.isAuthenticated; }

  constructor(
    private authService: AuthService,
  ) { }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
