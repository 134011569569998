<div class="header-container">
  <!--payee logo-->
  <div class="left">
    <payee-icon
      *ngIf="showPartnerLogo"
      [class.logo-clickable]="isLogoClickable"
      class="logo"
      [name]="partnerLogoName"
      (click)="onLogoClick()"
      [width]="partnerLogoName === 'payee-logo' ? '100px' : '170px'"
      height="auto"
    ></payee-icon>
  </div>

  <!--stepper-->
  <div class="stepper-wrapper vcenter">
    <div class="stepper-lines">
      <div class="flex vcenter">
        <div
          class="stepper-selector-line"
          [style.padding-left]="
            selectedStepIndex * +stepWidth.substring(0, stepWidth.length - 2) +
            'px'
          "
        >
          <div class="stepper-selector-circle"></div>
        </div>
        <div
          *ngFor="let step of steps.slice(1); let i = index"
          class="stepper-line"
          [style.width]="stepWidth"
        ></div>
      </div>
    </div>
    <div class="flex vcenter">
      <button
        *ngFor="let step of steps; let i = index"
        mat-button
        class="stepper-label"
        [style.width]="stepWidth"
        [class.active]="selectedStepIndex === i"
        (click)="navigateToStep(i)"
      >
        {{ (stepNames | async)[step] }}
      </button>
    </div>
  </div>

  <!--buttons-->
  <div class="buttons-wrapper right">
    <payee-language-selector></payee-language-selector>
    <a class="help-button" href="{{ environment.HELP_URL }}" target="_blank">
      {{ "CASE_FORM.HEADER.HELP" | translate }}
    </a>
    <button class="exit-button" (click)="onExitButtonClick()">
      {{ "CASE_FORM.HEADER.EXIT" | translate }}
    </button>
  </div>
</div>
