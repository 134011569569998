import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CashbookLoginFailedComponent
} from './components/cashbook/cashbook-login-failed/cashbook-login-failed.component';
import {
  CashbookLoginSuccessComponent
} from './components/cashbook/cashbook-login-success/cashbook-login-success.component';
import { ClaimsRerouterComponent } from './components/claims-rerouter/claims-rerouter.component';
import { AdminCasesComponent } from './components/dashboard/admin-cases/admin-cases.component';
import { ClientProfileComponent } from './components/dashboard/client-profile/client-profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserProfileComponent } from './components/dashboard/user-profile/user-profile.component';
import { EhazSsoErrorComponent } from './components/ehaz/ehaz-sso-error/ehaz-sso-error.component';
import { EhazSsoComponent } from './components/ehaz/ehaz-sso/ehaz-sso.component';
import {
  GetPasswordLinkSuccessComponent
} from './components/forgot-password/get-password-link-success/get-password-link-success.component';
import { GetPasswordLinkComponent } from './components/forgot-password/get-password-link/get-password-link.component';
import {
  NewPasswordSuccessComponent
} from './components/forgot-password/new-password-success/new-password-success.component';
import { NewPasswordComponent } from './components/forgot-password/new-password/new-password.component';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/login/register/register.component';
import { SignupFinalizationComponent } from './components/login/signup-finalization/signup-finalization.component';
import { TwofaFormComponent } from './components/login/twofa-form/twofa-form.component';
import { VerifyEmailComponent } from './components/login/verify-email/verify-email.component';
import { VerifyTokenExpiredComponent } from './components/login/verify-token-expired/verify-token-expired.component';
import {
  SzamlazzhuSsoErrorComponent
} from "./components/szamlazzhu/szamlazzhu-sso-error/szamlazzhu-sso-error.component";
import {
  SzamlazzhuSsoResponseComponent
} from "./components/szamlazzhu/szamlazzhu-sso-response/szamlazzhu-sso-response.component";
import { SzamlazzhuSsoComponent } from "./components/szamlazzhu/szamlazzhu-sso/szamlazzhu-sso.component";
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { LoadingScreenComponent } from './shared/loading-screen/loading-screen.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { AddNewClientOnboardingComponent } from './components/add-new-client/add-new-client-onboarding/add-new-client-onboarding.component';
import { AddNewClientFormComponent } from './components/add-new-client/add-new-client-form/add-new-client-form.component';
import { AddNewClientSuccessComponent } from './components/add-new-client/add-new-client-success/add-new-client-success.component';
import { StartCaseGuardService } from './services/start-case-guard/start-case-guard.service';
import { CaseFormComponent } from './components/case-form/case-form.component';
import { NoClientGuardService } from './services/no-client-guard.service';

const routes: Routes = [
  {
    path: 'user/add-new-client-onboarding',
    component: AddNewClientOnboardingComponent,
    canActivate: [AuthGuardService, NoClientGuardService],
  },
  {
    path: 'user/add-new-client',
    component: AddNewClientFormComponent,
    canActivate: [AuthGuardService, NoClientGuardService],
  },
  {
    path: 'user/add-new-client-success',
    component: AddNewClientSuccessComponent,
    canActivate: [AuthGuardService],
  },

  // Form Routes
  // Legacy routes
  { path: 'case/:uuid/data-entry', redirectTo: 'case/:uuid/debtor' },
  { path: 'case/:uuid/summary', redirectTo: 'case/:uuid/payment' },
  { path: 'cases/wizard', redirectTo: '/case' },
  // Form Init route
  {
    path: 'case',
    component: CaseFormComponent,
  },
  // Form Steps
  {
    path: 'case/:uuid',
    pathMatch: 'full',
    redirectTo: 'case/:uuid/',
  },
  {
    path: 'case/:uuid/:step',
    component: CaseFormComponent,
  },
  {
    path: 'user',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      // {path: 'api', component: ApiConnectionComponent},
      { path: 'user-profile', component: UserProfileComponent },
      { path: 'client-profile', component: ClientProfileComponent },
      // { path: 'campaign', component: CampaignComponent },
      { path: 'edit-case/:id', redirectTo: '/login' },
      {
        path: 'cases',
        component: AdminCasesComponent,
      },
      { path: '**', redirectTo: 'cases' }
    ]
  },

  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'verify-token-expired', component: VerifyTokenExpiredComponent },
  { path: 'signup-finalization', component: SignupFinalizationComponent },
  { path: 'forgot-password-get-link', component: GetPasswordLinkComponent },
  { path: 'forgot-password-get-link-success', component: GetPasswordLinkSuccessComponent },
  { path: 'forgot-password-new-password/:token', component: NewPasswordComponent },
  { path: 'forgot-password-new-password-success', component: NewPasswordSuccessComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout-success', redirectTo: '/login' },
  {
    path: '2fa', children: [
      { path: 'verify', component: TwofaFormComponent }
    ],
  },
  { path: 'signup', component: RegisterComponent },
  { path: 'beta-signup', redirectTo: 'signup' },
  {
    path: '_oauth',
    children: [
      { path: 'cashbook', redirectTo: '/login' },
      { path: 'cashbook_login_success', component: CashbookLoginSuccessComponent },
      { path: 'cashbook_login_failed', component: CashbookLoginFailedComponent },
      { path: '**', redirectTo: '/login' }
    ]
  },
  { path: 'claims/:claimToken', component: ClaimsRerouterComponent },
  { path: 'sso/ehaz', component: EhazSsoComponent },
  { path: 'sso/ehaz/error', component: EhazSsoErrorComponent },
  { path: 'sso/szamlazzhu', component: SzamlazzhuSsoComponent },
  { path: 'sso/szamlazzhu/error', component: SzamlazzhuSsoErrorComponent },
  { path: 'sso/szamlazzhu/response', component: SzamlazzhuSsoResponseComponent },
  { path: 'loading', component: LoadingScreenComponent },
  { path: 'user-guide', component: UserGuideComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
