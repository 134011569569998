import { FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { UserEmail } from "../form.resources";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";

export function getUserEmailForm(this: FormFactory): FormGroup {
  const data = this.data as UserEmail;

  const form = this.fb.group({
    id: data.id || new Date().valueOf().toString(),
    email: [data.email, [Validators.required, Validators.email]],
    terms_of_service: [false, Validators.requiredTrue],
  });

  form.statusChanges
    .pipe(
      debounceTime(1000),
      filter(() => form.valid),
    )
    .subscribe({
      next: async () => {
        try {
          await this.formService.updateGuestUserEmail(form.value);
        } catch (error) {
          console.error('Error while updating guest user email', error);
        }
      }
    });

  return form;
}