import { FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { Debtor } from "../form.resources";
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from "rxjs/operators";
import { errorResponse } from "src/types/error-response";
import { AxiosError } from "axios";

export function getDebtorForm(this: FormFactory): FormGroup {
  const data = this.data as Debtor;
  const isEditable = data?.is_editable !== false;

  const form = this.fb.group({
    id: data?.id ?? (this.formService.isGuest ? new Date().valueOf().toString() : null),
    jurisdiction: [data.jurisdiction, [Validators.required]],
    debtor_type: [data.debtor_type, [Validators.required, Validators.pattern(/ind|org/)]],
    name: [data.name, [Validators.required, Validators.maxLength(255)]],
    // @todo required if org and not house
    tax_number: data.tax_number,
    address: this.fb.group({
      country_iso: [data.address.country_iso || 'HU', Validators.required],
      postcode: [data.address.postcode, [Validators.required, Validators.maxLength(255)]],
      settlement: [data.address.settlement, [Validators.required, Validators.maxLength(255)]],
      street: [data.address.street, [Validators.required, Validators.maxLength(255)]],
    }),
    is_editable: isEditable,
  });

  const tax_number = form.get('tax_number');
  const jurisdiction = form.get('jurisdiction');

  if (isEditable === false) {
    if (form.valid) {
      form.disable({ emitEvent: false, onlySelf: true });
      return form;
    } else {
      jurisdiction.disable({ emitEvent: false, onlySelf: true });
    }
  }

  jurisdiction.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe({
      next: async jurisdiction => {
        try {
          form.disable();
          await this.formService.changeJurisdiction(jurisdiction);
        } catch (error) {
          console.error('Error while changing jurisdiction', error);
        }
      }
    });

  form.valueChanges
    .pipe(startWith(data))
    .subscribe({
      next: (v: Debtor) => {
        if (v.debtor_type === 'ind') {
          tax_number.disable({ onlySelf: true, emitEvent: false });
        } else {
          tax_number.enable({ onlySelf: true, emitEvent: false });
        }
        form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      },
    });

  form.valueChanges
    .pipe(
      debounceTime(1000),
      takeUntil(this.formService.jurisdictionChange),
      filter(() => form.valid),
    )
    .subscribe({
      next: async () => {
        try {
          await this.formService.upsertDebtor(form.value);
        } catch (error) {
          console.error('Error while saving debtor', error);
          if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
              const errorData = errorResponse.safeParse(error.response?.data);
              if (errorData.success) {
                Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                  form.get(path)?.setErrors({
                    unknown: errors[0],
                  }, {
                    emitEvent: false,
                  });
                });
              }
            }
          }
        }
      },
    });

  return form;
}
