<ng-template #formError>
  <span
    *ngIf="form.dirty && form.invalid"
    class="top-margin-15"
    color="alert-red"
  >
    <ng-container *ngIf="form.hasError('max')">
      <!-- TODO: currency -->
      {{
        "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.ERRORS.TOTAL_EXCEEDS_MAX"
          | translate : { max: form.errors.max.max | number : "1.0" : "hu" }
      }}
    </ng-container>
    <ng-container *ngIf="form.hasError('required')">
      {{
        "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.ERRORS.AT_LEAST_ONE_REQUIRED"
          | translate
      }}
    </ng-container>
    <!-- Form is invalid but only child controls have errors -->
    <ng-container *ngIf="form.errors === null">
      <ng-container *ngFor="let invoiceForm of form.controls">
        <div *ngIf="invoiceForm.get('file').hasError('required')">
          {{
            "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.ERRORS.FILE_REQUIRED"
              | translate : { invoiceNumber: invoiceForm.value.invoice_number }
          }}
        </div>
      </ng-container>
    </ng-container>
  </span>
</ng-template>

<div class="flex vcenter title">
  <h2>{{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.TITLE" | translate }}</h2>
  <mat-progress-spinner
    *ngIf="invoicesLoading"
    mode="indeterminate"
    diameter="24"
  ></mat-progress-spinner>
</div>

<div *ngIf="ksziSyncInProgress" class="kszi-warning flex vcenter top-margin-10">
  <mat-progress-spinner
    mode="indeterminate"
    diameter="30"
  ></mat-progress-spinner>
  <div class="flex column">
    <span>{{
      "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.KSZI_WARNING.LOADING_IN_PROGRESS"
        | translate
    }}</span>
    <span>
      {{
        "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.KSZI_WARNING.EXPECTED_TIME_TO_FINISH"
          | translate
            : {
                estimated_time:
                  ksziEstimatedFinishTime | date : "yyyy.MM.dd. HH:mm"
              }
      }}
    </span>
  </div>
</div>

<div *ngIf="hasMultipleInvoices" class="description top-margin-10">
  {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.DESCRIPTION" | translate }}
</div>

<div class="flex column invoice-filters top-margin-10">
  <payee-form-field>
    <span payee-label>{{
      "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.FILTER_LABEL" | translate
    }}</span>
    <input payee-input [formControl]="invoiceFilter" />
  </payee-form-field>
  <mat-checkbox [formControl]="hideInvalidInvoices">
    {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.HIDE_INVALID_INVOICES" | translate }}
  </mat-checkbox>
</div>

<section class="forms flex column">
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="top-margin-15 mat-elevation-z1"
  >
    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="alt-theme">
        <div class="flex vcenter hcenter">
          <mat-checkbox
            *ngIf="getInvoiceSelector(element)?.enabled"
            [disabled]="invoicesLoading"
            [checked]="getInvoiceSelector(element).value.selected"
            (change)="toggleInvoice(element)"
            id="invoice-checkbox-{{ element.invoice_id }}"
            color="accent"
          ></mat-checkbox>
          <mat-icon
            *ngIf="element.error_code"
            color="warn"
            [matTooltip]="
              'CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.ERROR_CODES.' +
                element.error_code | translate
            "
            matTooltipClass="custom-tooltip"
            matTooltipPosition="above"
          >
            warning_amber
          </mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="invoice_number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.INVOICE_NUMBER" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoice_number }}
      </td>
    </ng-container>
    <ng-container matColumnDef="due_date_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.DUE_DATE" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.due_date_at | date : "yyyy.MM.dd." }}
      </td>
    </ng-container>
    <ng-container matColumnDef="remaining_amount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.AMOUNT" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.total_remaining_amount | number : "1.0" : "hu" }}
        {{ element.currency_iso }}
      </td>
    </ng-container>
    <ng-container matColumnDef="file">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.FILE" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="
            !element.file && getInvoiceSelector(element)?.value.index !== null
          "
        >
          <input
            hidden
            #upload
            type="file"
            accept="
              application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document,
              application/vnd.ms-powerpoint,
              application/vnd.openxmlformats-officedocument.presentationml.presentation,
              text/plain,
              application/vnd.ms-xpsdocument,
              image/jpg,
              image/jpeg,
              image/png,
              image/gif,
              image/bmp,
              image/tiff,
              image/svg+xml,
              image/vnd.adobe.photoshop,
              application/pdf,
              application/zip,
              application/x-rar-compressed,
              application/octet-stream,
              application/gzip,
              application/x-tar,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            "
            (click)="$event.stopPropagation()"
            (change)="uploadInvoice($event, element)"
          />
          <a
            class="invalid-file"
            (click)="$event.stopPropagation(); upload.click()"
          >
            {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.UPLOAD_FILE" | translate }}
          </a>
        </ng-container>
        <ng-container *ngIf="element.file">
          <a
            *ngIf="element.file.storage_url"
            (click)="$event.stopPropagation()"
            [href]="element.file.storage_url"
            target="_blank"
            class="valid-file"
          >
            {{ "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.FILE_OK" | translate }}
          </a>
          <div
            *ngIf="!element.file.storage_url"
            class="flex vcenter valid-file"
          >
            <span>{{
              "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.FILE_OK" | translate
            }}</span>
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="corrections">
      <td mat-cell *matCellDef="let element" colspan="6">
        <div class="correction-title">
          {{
            "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.CORRECTIONS.INVOICE_TITLE"
              | translate
          }}
        </div>
        <table class="correction-table">
          <td class="index">1.</td>
          <td class="invoice-number">{{ element.invoice_number }}</td>
          <td class="amount">
            {{ element.remaining_amount | number : "1.0" : "hu" }}
            {{ element.currency_iso }}
          </td>
        </table>
        <div class="correction-title">
          {{
            "CASE_FORM.FORMS.SZAMLAZZHU_INVOICES.CORRECTIONS.CORRECTIONS_TITLE"
              | translate
          }}
        </div>
        <table class="correction-table">
          <tr *ngFor="let correction of element.corrections; let i = index">
            <td class="index">{{ i + 2 }}.</td>
            <td class="invoice-number">{{ correction.invoice_number }}</td>
            <td class="amount">
              {{ correction.remaining_amount | number : "1.0" : "hu" }}
              {{ correction.currency_iso }}
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="select-row"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
    <tr
      mat-row
      class="corrections-row"
      [class.has-corrections]="row.corrections.length > 0"
      *matRowDef="let row; columns: ['corrections']"
    ></tr>
  </table>

  <mat-paginator
    *ngIf="dataSource.data.length > 5"
    pageSize="5"
    [length]="dataSource.data.length"
    [pageSizeOptions]="[5, 10]"
    disableUnderline
    showFirstLastButtons
    class="mat-elevation-z1"
  ></mat-paginator>

  <ng-template [ngTemplateOutlet]="formError"></ng-template>
</section>
