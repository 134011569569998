<form [formGroup]="form">
  <payee-form-field class="name-form-field">
    <input
      payee-input
      formControlName="name"
      [placeholder]="'CASE_FORM.FORMS.CLIENT.ORG.NAME_PLACEHOLDER' | translate"
      [matAutocomplete]="auto"
    />
    <ng-container *ngIf="name.invalid && name.dirty" payee-error>
      <span *ngIf="name.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </span>
      <span *ngIf="name.hasError('maxlength')">
        {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
      </span>
    </ng-container>
    <span payee-suffix *ngIf="autocompleteSearchLoading">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="24"
      ></mat-progress-spinner>
    </span>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="companySelected($event)"
    >
      <mat-option *ngFor="let option of companySearchOptions" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </payee-form-field>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <payee-autocomplete
        formControlName="party_type_id"
        [options]="partyTypeOptions"
        [displayWith]="partyTypeDisplayWith"
        [placeholder]="
          'CASE_FORM.FORMS.CLIENT.ORG.PARTY_TYPE_PLACEHOLDER' | translate
        "
      >
        <ng-template #menuDisplayTemplate>
          <span>{{ (clientPartyType | async)?.label }}</span>
        </ng-template>
      </payee-autocomplete>
      <ng-container
        *ngIf="party_type_id.invalid && party_type_id.dirty"
        payee-error
      >
        <span *ngIf="party_type_id.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="tax_number"
        [placeholder]="
          'CASE_FORM.FORMS.CLIENT.ORG.TAX_NUMBER_PLACEHOLDER' | translate
        "
      />
      <ng-container *ngIf="tax_number.invalid && tax_number.dirty" payee-error>
        <span *ngIf="tax_number.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="tax_number.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="registration_number"
        [placeholder]="
          'CASE_FORM.FORMS.CLIENT.ORG.REGISTRATION_NUMBER_PLACEHOLDER'
            | translate
        "
      />
      <ng-container
        *ngIf="registration_number.invalid && registration_number.dirty"
        payee-error
      >
        <span *ngIf="registration_number.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="registration_number.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="form-group-label top-margin-15">
    {{ "CASE_FORM.FORMS.CLIENT.ORG.HEADQUARTERS_LABEL" | translate }}
  </div>
  <form formGroupName="address">
    <div class="top-margin-15">
      <payee-dropdown
        formControlName="country_iso"
        [options]="countryOptions"
        width="widest"
        height="tall"
        [displayWith]="countryDisplayWith"
      >
        <ng-template #menuDisplayTemplate>
          <span>{{ clientCountry.value?.name?.value }}</span>
        </ng-template>
      </payee-dropdown>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="postcode"
          [placeholder]="
            'CASE_FORM.FORMS.CLIENT.ORG.POSTCODE_PLACEHOLDER' | translate
          "
        />
        <ng-container
          *ngIf="clientPostcode.invalid && clientPostcode.dirty"
          payee-error
        >
          <span *ngIf="clientPostcode.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </span>
          <span *ngIf="clientPostcode.hasError('maxlength')">
            {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="settlement"
          [placeholder]="
            'CASE_FORM.FORMS.CLIENT.ORG.SETTLEMENT_PLACEHOLDER' | translate
          "
        />
        <ng-container
          *ngIf="clientSettlement.invalid && clientSettlement.dirty"
          payee-error
        >
          <span *ngIf="clientSettlement.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </span>
          <span *ngIf="clientSettlement.hasError('maxlength')">
            {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="street"
          [placeholder]="
            'CASE_FORM.FORMS.CLIENT.ORG.STREET_PLACEHOLDER' | translate
          "
        />
        <ng-container
          *ngIf="clientStreet.invalid && clientStreet.dirty"
          payee-error
        >
          <span *ngIf="clientStreet.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </span>
          <span *ngIf="clientStreet.hasError('maxlength')">
            {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>
  </form>

  <div *ngIf="otherDataEnabled" class="form-group-label top-margin-15">
    {{
      name.value || ("CASE_FORM.FORMS.CLIENT.ORG.OTHER_DATA_LABEL" | translate)
    }}
  </div>

  <div *ngIf="mother_name.enabled" class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="mother_name"
        [placeholder]="
          'CASE_FORM.FORMS.CLIENT.ORG.MOTHER_NAME_PLACEHOLDER' | translate
        "
      />
      <ng-container
        *ngIf="mother_name.invalid && mother_name.dirty"
        payee-error
      >
        <span *ngIf="mother_name.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="mother_name.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div *ngIf="birth_place.enabled" class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="birth_place"
        [placeholder]="
          'CASE_FORM.FORMS.CLIENT.ORG.BIRTH_PLACE_PLACEHOLDER' | translate
        "
      />
      <ng-container
        *ngIf="birth_place.invalid && birth_place.dirty"
        payee-error
      >
        <span *ngIf="birth_place.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="birth_place.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div *ngIf="birth_date.enabled" class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <payee-datepicker
        formControlName="birth_date"
        [max]="maxBirthDate"
        [placeholder]="
          'CASE_FORM.FORMS.CLIENT.ORG.BIRTH_DATE_PLACEHOLDER' | translate
        "
      ></payee-datepicker>
      <ng-container *ngIf="birth_date.invalid && birth_date.dirty" payee-error>
        <span *ngIf="birth_date.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="birth_date.hasError('maxdate')">
          {{ "CASE_FORM.FORMS.CLIENT.ORG.ERRORS.MAX_DATE" | translate }}
        </span>
        <span *ngIf="birth_date.hasError('unknown')">{{
          birth_date.errors.unknown
        }}</span>
      </ng-container>
    </payee-form-field>
  </div>

  <ng-container *ngIf="form.get('representative').enabled">
    <div class="form-group-label top-margin-15">
      {{
        "CASE_FORM.FORMS.CLIENT.ORG.REPRESENTATIVE_LABEL"
          | translate
            : {
                name:
                  name.value ||
                  ("CASE_FORM.FORMS.CLIENT.ORG.CLIENT_PLACEHOLDER_NAME" | translate)
              }
      }}
    </div>

    <form formGroupName="representative">
      <div class="top-margin-15">
        <payee-form-field width="widest" height="tall">
          <input
            payee-input
            formControlName="name"
            [placeholder]="
              'CASE_FORM.FORMS.CLIENT.ORG.REPRESENTATIVE_NAME_PLACEHOLDER'
                | translate
            "
          />
          <ng-container *ngIf="repName.invalid && repName.dirty" payee-error>
            <span *ngIf="repName.hasError('required')">
              {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
            </span>
            <span *ngIf="repName.hasError('maxlength')">
              {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
            </span>
          </ng-container>
        </payee-form-field>
      </div>

      <form formGroupName="address">
        <div class="top-margin-15">
          <payee-dropdown
            formControlName="country_iso"
            [options]="countryOptions"
            width="widest"
            height="tall"
            [displayWith]="countryDisplayWith"
          >
            <ng-template #menuDisplayTemplate>
              <span>{{ representativeCountry.value?.name?.value }}</span>
            </ng-template>
          </payee-dropdown>
        </div>

        <div class="top-margin-15">
          <payee-form-field width="widest" height="tall">
            <input
              payee-input
              formControlName="postcode"
              [placeholder]="
                'CASE_FORM.FORMS.CLIENT.ORG.POSTCODE_PLACEHOLDER' | translate
              "
            />
            <ng-container
              *ngIf="repPostcode.invalid && repPostcode.dirty"
              payee-error
            >
              <span *ngIf="repPostcode.hasError('required')">
                {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
              </span>
              <span *ngIf="repPostcode.hasError('maxlength')">
                {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
              </span>
            </ng-container>
          </payee-form-field>
        </div>

        <div class="top-margin-15">
          <payee-form-field width="widest" height="tall">
            <input
              payee-input
              formControlName="settlement"
              [placeholder]="
                'CASE_FORM.FORMS.CLIENT.ORG.SETTLEMENT_PLACEHOLDER' | translate
              "
            />
            <ng-container
              *ngIf="repSettlement.invalid && repSettlement.dirty"
              payee-error
            >
              <span *ngIf="repSettlement.hasError('required')">
                {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
              </span>
              <span *ngIf="repSettlement.hasError('maxlength')">
                {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
              </span>
            </ng-container>
          </payee-form-field>
        </div>

        <div class="top-margin-15">
          <payee-form-field width="widest" height="tall">
            <input
              payee-input
              formControlName="street"
              [placeholder]="
                'CASE_FORM.FORMS.CLIENT.ORG.STREET_PLACEHOLDER' | translate
              "
            />
            <ng-container
              *ngIf="repStreet.invalid && repStreet.dirty"
              payee-error
            >
              <span *ngIf="repStreet.hasError('required')">
                {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
              </span>
              <span *ngIf="repStreet.hasError('maxlength')">
                {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
              </span>
            </ng-container>
          </payee-form-field>
        </div>
      </form>
    </form>
  </ng-container>
</form>
