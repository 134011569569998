import { ComponentType } from "@angular/cdk/portal";
import { HuClientFormComponent } from "src/app/components/case-form/forms/hu-client-form/hu-client-form.component";
import { HuDebtorFormComponent } from "src/app/components/case-form/forms/hu-debtor-form/hu-debtor-form.component";
import { SzamlazzhuInvoicesFormComponent } from "src/app/components/case-form/forms/szamlazzhu-invoices-form/szamlazzhu-invoices-form.component";
import { UserEmailFormComponent } from "src/app/components/case-form/forms/user-email-form/user-email-form.component";
import { FormName } from "./form.factory";
import { DebtorFormComponent } from "src/app/components/case-form/forms/debtor-form/debtor-form.component";
import { ClaimsFormComponent } from "src/app/components/case-form/forms/claims-form/claims-form.component";
import { ClientFormComponent } from "src/app/components/case-form/forms/client-form/client-form.component";
import { SummaryFormComponent } from "src/app/components/case-form/forms/summary-form/summary-form.component";
import { BankAccountFormComponent } from "src/app/components/case-form/forms/bank-account-form/bank-account-form.component";

export const formComponents: Record<FormName, ComponentType<unknown>> = {
  // GLOBAL
  "user": UserEmailFormComponent,

  // Default
  'debtor': DebtorFormComponent,
  'claims': ClaimsFormComponent,
  'claim': null,
  'client': ClientFormComponent,
  "bank-account": BankAccountFormComponent,
  "summary": SummaryFormComponent,

  // HU
  'hu-debtor': HuDebtorFormComponent,
  'hu-client': HuClientFormComponent,

  // Szamlazz.hu
  "szamlazzhu-invoice": null,
  "szamlazzhu-invoices": SzamlazzhuInvoicesFormComponent,

  // Helper
  campaign: null,
};
