<payee-popup
  title="{{ 'CASE_FORM.EXIT_DIALOG.TITLE' | translate }}"
  description="{{ 'CASE_FORM.EXIT_DIALOG.DESCRIPTION' | translate }}"
>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <div class="arrow-button flex vcenter hcenter">
        <span>{{ 'CASE_FORM.EXIT_DIALOG.SUBMIT' | translate }}</span>
        <payee-icon
          height="12px"
          name="arrow-right-white">
        </payee-icon>
      </div>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="wide"
      height="normal"
    >
      <div class="cancel-button flex vcenter hcenter">
        <span>{{ 'CASE_FORM.EXIT_DIALOG.CANCEL' | translate }}</span>
        <payee-icon
          name="arrow-right-grey"
          width="auto"
          height="12px"
        ></payee-icon>
      </div>
    </button>
  </ng-template>
</payee-popup>
