<h2>{{ "CASE_FORM.FORMS.SUMMARY.TITLE" | translate }}</h2>
<section class="summary-data top-margin-20">
  <!-- Client -->
  <div class="flex vcenter">
    <div class="grow1 summary-label">
      {{ "CASE_FORM.FORMS.SUMMARY.CLIENT_LABEL" | translate }}
    </div>
    <div class="summary-value">{{ summary.client.name }}</div>
  </div>
  <!-- Debtor -->
  <div class="flex vcenter">
    <div class="grow1 summary-label">
      {{ "CASE_FORM.FORMS.SUMMARY.DEBTOR_LABEL" | translate }}
    </div>
    <div class="summary-value">{{ summary.debtor.name }}</div>
  </div>
  <!-- Claims -->
  <div>
    <div class="flex vcenter">
      <div class="grow1 summary-label">
        {{ "CASE_FORM.FORMS.SUMMARY.CLAIMS_LABEL" | translate }}
      </div>
      <div class="summary-label">
        {{
          summary.payee_case.displayed_claims_sum_original_amount
            | number : "1.0" : "hu"
        }}
        {{ summary.payee_case.currency_iso }}
      </div>
    </div>
    <div class="top-margin-5">
      <div
        *ngFor="let claim of summary.claims; let i = index"
        class="flex vcenter"
      >
        <div class="grow1 claim-item">
          {{ i + 1 }}.
          {{ "CASE_FORM.FORMS.SUMMARY.CLAIM_ITEM_LABEL" | translate }} ({{
            "CASE_FORM.FORMS.SUMMARY.CLAIM_DUE_LABEL" | translate
          }}
          {{ claim.due_date_at | date : "yyyy.MM.dd." }})
        </div>
        <div class="claim-item">
          {{ claim.original_amount | number : "1.0" : "hu" }}
          {{ claim.currency_iso }}
        </div>
      </div>
    </div>
  </div>
  <!-- Optional Claims -->
  <div *ngIf="showOptionalClaimsForm" class="optional-claims">
    <div class="optional-claim-title flex vcenter">
      <payee-icon name="plus-black" width="15" height="15"></payee-icon>
      &nbsp;{{ "CASE_FORM.FORMS.SUMMARY.OPTIONAL_CLAIMS_TITLE" | translate }}
    </div>
    <payee-toggle
      *ngIf="showInterestAddedForm"
      [disabled]="loading"
      [selected]="isInterestAdded.value"
      [text]="
        !interestAmount
          ? ('CASE_FORM.FORMS.SUMMARY.INTEREST_OPTIONAL_CLAIM_LABEL_UNKNOWN'
            | translate)
          : ('CASE_FORM.FORMS.SUMMARY.INTEREST_OPTIONAL_CLAIM_LABEL'
            | translate
              : {
                  amount: interestAmount,
                  currency: summary.payee_case.currency_iso
                })
      "
      (selectionChanged)="onInterestToggle($event)"
      matTooltip="{{ 'CASE_FORM.FORMS.SUMMARY.INTEREST_TOOLTIP' | translate }}"
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
      direction="top"
    ></payee-toggle>
    <payee-toggle
      *ngIf="showFlatRateCostAddedForm"
      [selected]="isFlatRateCostClaimAdded.value"
      [disabled]="loading"
      [text]="
        !flatRateCostAmount
          ? ('CASE_FORM.FORMS.SUMMARY.FLAT_RATE_OPTIONAL_CLAIM_LABEL_UNKNOWN'
            | translate)
          : ('CASE_FORM.FORMS.SUMMARY.FLAT_RATE_OPTIONAL_CLAIM_LABEL'
            | translate
              : {
                  amount: flatRateCostAmount,
                  currency: summary.payee_case.currency_iso
                })
      "
      (selectionChanged)="onFlatRateCostClaimToggle($event)"
      matTooltip="{{ 'CASE_FORM.FORMS.SUMMARY.FLAT_RATE_TOOLTIP' | translate }}"
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
    ></payee-toggle>
    <payee-toggle
      *ngIf="isStartCasePriceClaimAdded.value"
      [selected]="true"
      [disabled]="true"
      text="{{
        'CASE_FORM.FORMS.SUMMARY.START_CASE_FEE_OPTIONAL_CLAIM_LABEL'
          | translate
            : {
                start_fee_amount: startCaseFeeAmount | number : '1.0' : 'hu',
                currency: summary.product.currency_iso
              }
      }}"
      matTooltip="{{
        'CASE_FORM.FORMS.SUMMARY.START_CASE_TOOLTIP' | translate
      }}"
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
    ></payee-toggle>
  </div>
  <!-- All Claims -->
  <div class="all-claims">
    <div class="flex vcenter">
      <div class="summary-label grow1">
        {{ "CASE_FORM.FORMS.SUMMARY.ALL_CLAIMS_LABEL" | translate }}
      </div>
      <div class="summary-label">
        {{
          summary.payee_case.claims_sum_original_amount | number : "1.0" : "hu"
        }}
        {{ summary.payee_case.currency_iso }}
      </div>
    </div>
    <div class="all-claims-description">
      {{
        "CASE_FORM.FORMS.SUMMARY.ALL_CLAIMS_DESCRIPTION"
          | translate : { name: summary.debtor.name }
      }}
    </div>
  </div>
</section>

<h2 class="top-margin-30">
  {{ "CASE_FORM.FORMS.SUMMARY.ACTION_AND_PRICING_TITLE" | translate }}
</h2>
<div *ngIf="showProductForms" class="payment-method-description">
  <span>
    {{ "CASE_FORM.FORMS.SUMMARY.HELP_TEXT" | translate }}
  </span>
  <a
    href="https://help.payee.tech/hu/articles/6423716-melyik-modult-valasszam"
    target="_blank"
  >
    {{ "CASE_FORM.FORMS.SUMMARY.MORE_INFO" | translate }}
  </a>
</div>
<!-- For some reason binding the control directly causes change after check NG0100 errors -->
<mat-radio-group
  [formControl]="productType"
  class="product-type-selector flex column alt-theme top-margin-20"
  color="primary"
>
  <mat-radio-button
  *ngIf="showProduct['notice']"
    value="notice"
  >
    <div class="product-type-title">
      {{ "CASE_FORM.FORMS.SUMMARY.NOTICE_TITLE" | translate }}
    </div>
    <div class="product-type-description">
      {{ "CASE_FORM.FORMS.SUMMARY.NOTICE_DESCRIPTION" | translate }}
    </div>
  </mat-radio-button>
  <mat-radio-button
  *ngIf="showProduct['demand_letter']"
    value="demand_letter"
  >
    <div class="product-type-title">
      {{ "CASE_FORM.FORMS.SUMMARY.DEMAND_LETTER_TITLE" | translate }}
    </div>
    <div class="product-type-description">
      {{ "CASE_FORM.FORMS.SUMMARY.DEMAND_LETTER_DESCRIPTION" | translate }}
    </div>
  </mat-radio-button>
  <mat-radio-button
    *ngIf="showProduct['hard_1']"
    value="hard_1"
  >
    <div class="flex vcenter hard-radio-wrapper">
      <div>
        <div class="product-type-title">
          {{ "CASE_FORM.FORMS.SUMMARY.HARD_1_TITLE" | translate }}
        </div>
        <div class="product-type-description">
          {{ "CASE_FORM.FORMS.SUMMARY.HARD_1_DESCRIPTION" | translate }}
        </div>
      </div>
      <a
        href="https://help.payee.tech/hu/articles/6423716-melyik-modult-valasszam"
        target="_blank"
        class="flex vcenter hard-recommended-box alt-theme"
        matTooltipClass="custom-tooltip"
        matTooltipPosition="above"
        matTooltip="{{
          'CASE_FORM.FORMS.SUMMARY.HARD_1_TOOLTIP'
            | translate
              : {
                  hard_2_price:
                    summary.product.hard_2_price.amount | number : '1.0' : 'hu',
                  hard_2_min_price:
                    summary.product.hard_2_price.min | number : '1.0' : 'hu',
                  hard_2_max_price:
                    summary.product.hard_2_price.max | number : '1.0' : 'hu'
                }
        }}"
      >
        <payee-icon name="info-grey-outline"></payee-icon>
        <span class="recommended-chip">
          {{ "CASE_FORM.FORMS.SUMMARY.RECOMMENDED_LABEL" | translate }}
        </span>
      </a>
    </div>
  </mat-radio-button>
</mat-radio-group>

<!-- Fees -->
<div class="fees top-margin-20">
  <div *ngIf="startCaseFeeAmount > 0" class="flex vcenter bottom-margin-5">
    <div class="grow1 start-fee-title">
      {{ "CASE_FORM.FORMS.SUMMARY.START_CASE_FEE_LABEL" | translate }}
    </div>
    <div class="start-fee-title">
      {{ startCaseFeeAmount | number : "1.0" : "hu" }}
      {{ summary.product.currency_iso }}
    </div>
  </div>
  <div *ngIf="successCaseFeeAmount > 0" class="flex vcenter bottom-margin-5">
    <div class="grow1 start-fee-title">
      {{ "CASE_FORM.FORMS.SUMMARY.SUCCESS_FEE_LABEL" | translate }}
    </div>
    <div class="start-fee-title">
      {{ successCaseFeeAmount | number : "1.0" : "hu" }}
      {{ summary.product.currency_iso }}
    </div>
  </div>
  <ng-container *ngFor="let fees of summary.fees | keyvalue">
    <div *ngFor="let fee of fees.value" class="flex vcenter">
      <div class="grow1 fee-text">
        {{ fee.label }}
      </div>
      <span class="fee-detail">{{ fee.details_text }}&nbsp;</span>
      <span class="fee-text">
        {{ fee.amount | number : "1.0" : "hu" }}
        {{ summary.product.currency_iso }}
      </span>
    </div>
  </ng-container>
</div>

<!-- Debtor Email -->
<ng-container *ngIf="showDebtorForm">
  <h2 class="top-margin-20">
    {{ "CASE_FORM.FORMS.SUMMARY.DEBTOR_EMAIL_TITLE" | translate }}
  </h2>
  <div class="debtor-email-description top-margin-10">
    {{ "CASE_FORM.FORMS.SUMMARY.DEBTOR_EMAIL_DESCRIPTION" | translate }}
  </div>
  <div class="top-margin-20">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        [placeholder]="
          'CASE_FORM.FORMS.SUMMARY.DEBTOR_EMAIL_PLACEHOLDER' | translate
        "
        [formControl]="debtorEmail"
      />
    </payee-form-field>
  </div>
</ng-container>

<!-- Payment Method -->
<ng-container *ngIf="startCaseFeeAmount > 0">
  <h2 class="top-margin-30">
    {{ "CASE_FORM.FORMS.SUMMARY.PAYMENT_METHOD_TITLE" | translate }}
  </h2>
  <mat-radio-group
    [formControl]="paymentMethod"
    class="payment-method-selector flex column alt-theme top-margin-20"
    color="primary"
  >
    <mat-radio-button value="card">
      <div class="flex vcenter">
        <div class="flex column grow1">
          <div class="payment-method-title">
            {{ "CASE_FORM.FORMS.SUMMARY.CARD_PAYMENT_TITLE" | translate }}
          </div>
          <div class="payment-method-description">
            {{ "CASE_FORM.FORMS.SUMMARY.CARD_PAYMENT_DESCRIPTION" | translate }}
          </div>
        </div>
        <img
          src="assets/shared/global-images/partner-logos/barion.svg"
          width="151px"
          alt="barion"
        />
      </div>
    </mat-radio-button>
    <mat-radio-button value="transfer">
      <div class="payment-method-title">
        {{ "CASE_FORM.FORMS.SUMMARY.TRANSFER_PAYMENT_TITLE" | translate }}
      </div>
      <div class="payment-method-description">
        {{ "CASE_FORM.FORMS.SUMMARY.TRANSFER_PAYMENT_DESCRIPTION" | translate }}
      </div>
    </mat-radio-button>
  </mat-radio-group>
  <button
    [disabled]="form.invalid || loading || hasUnsavedChanges"
    (click)="startCaseWithPayment()"
    payee-button
    type="button"
    class="top-margin-25"
    width="widest"
    height="tall"
  >
    <span *ngIf="paymentMethod.value === 'card'">
      {{ startCaseFeeAmount | number : "1.0" : "hu" }}
      {{ summary.product.currency_iso }}
      {{ "CASE_FORM.FORMS.SUMMARY.PAY_CARD_LABEL" | translate }}
    </span>
    <span *ngIf="paymentMethod.value === 'transfer'">
      {{ startCaseFeeAmount | number : "1.0" : "hu" }}
      {{ summary.product.currency_iso }}
      {{ "CASE_FORM.FORMS.SUMMARY.PAY_TRANSFER_LABEL" | translate }}
    </span>
  </button>
  <div class="payment-disclaimer top-margin-20">
    {{ "CASE_FORM.FORMS.SUMMARY.PAYMENT_DISCLAIMER" | translate }}
  </div>
</ng-container>
<ng-container *ngIf="startCaseFeeAmount === 0">
  <button
    [disabled]="form.invalid || loading || hasUnsavedChanges"
    (click)="startInstantly()"
    payee-button
    type="button"
    class="top-margin-25"
    width="widest"
    height="tall"
  >
    {{ "CASE_FORM.FORMS.SUMMARY.START_NOW_BUTTON" | translate }}
  </button>
  <div class="payment-disclaimer top-margin-20">
    {{ "CASE_FORM.FORMS.SUMMARY.INSTANT_PAYMENT_DISCLAIMER" | translate }}
  </div>
</ng-container>
