import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app-dev-payee';

  private readonly langKey = 'lang' as const;
  private readonly destroy = new Subject<void>();

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translate.addLangs(['hu', 'en', 'sk']);
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang();
    const savedLang = localStorage.getItem(this.langKey);

    const usedLang = savedLang || browserLang;
    this.translate.use(this.translate.langs.includes(usedLang) ? usedLang : 'en');
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
