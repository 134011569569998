<div class="small-dialog-container">
  <!--success icon-->
  <payee-icon name="success-icon" class="success-icon" width="80" height="80"></payee-icon>

  <!--title-->
  <div class="small-dialog-title top-margin-20">
    {{ 'CASE_FORM.FORMS.SUMMARY.TRANSFER_DIALOG.TITLE' | translate }}
  </div>

  <!--description-->
  <div class="small-dialog-description top-margin-15">
    {{ 'CASE_FORM.FORMS.SUMMARY.TRANSFER_DIALOG.DESCRIPTION' | translate }}
  </div>

  <form [formGroup]="form" class="flex column copy-form top-margin-10">
    <payee-form-field width="wide">
      <span payee-label>
        {{ 'CASE_FORM.FORMS.SUMMARY.TRANSFER_DIALOG.AMOUNT_LABEL' | translate }}
      </span>
      <span payee-suffix style="margin-right: 30px">{{ data.currency_iso }}</span>
      <payee-copy-form-field
        formControlName="transfer_amount"
      ></payee-copy-form-field>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>
        {{ 'CASE_FORM.FORMS.SUMMARY.TRANSFER_DIALOG.BENEFICIARY_LABEL' | translate }}
      </span>
      <payee-copy-form-field
        formControlName="account_holder_name"
      ></payee-copy-form-field>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>
        {{ 'CASE_FORM.FORMS.SUMMARY.TRANSFER_DIALOG.ACCOUNT_NUMBER_LABEL' | translate }}
      </span>
      <payee-copy-form-field
        formControlName="account_number"
      ></payee-copy-form-field>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>
        {{ 'CASE_FORM.FORMS.SUMMARY.TRANSFER_DIALOG.REFERENCE_LABEL' | translate }}
      </span>
      <payee-copy-form-field
        formControlName="payee_case_reference_id"
      ></payee-copy-form-field>
    </payee-form-field>
  </form>

  <button
    mat-dialog-close
    class="top-margin-30"
    payee-button
    width="wide"
    height="tall"
  >
    <div class="arrow-button flex vcenter hcenter">
      <span>
        {{ 'CASE_FORM.FORMS.SUMMARY.TRANSFER_DIALOG.BUTTON_LABEL' | translate }}
      </span>
      <payee-icon name="arrow-right-white"></payee-icon>
    </div>
  </button>
</div>
