import { FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { Client } from "../form.resources";
import { debounceTime, filter } from "rxjs/operators";
import { AxiosError } from "axios";
import { errorResponse } from "src/types/error-response";

export function getClientForm(this: FormFactory): FormGroup {
  const data = this.data as Client;

  const form = this.fb.group({
    id: data?.id ?? (this.formService.isGuest ? new Date().valueOf().toString() : null),
    client_type: [data.client_type, [Validators.required, Validators.pattern(/org|house/)]],
    name: [data.name, [Validators.required, Validators.maxLength(255)]],
    // @todo required if org and not house
    tax_number: data.tax_number,
    address: this.fb.group({
      country_iso: [data.address.country_iso || 'HU', Validators.required],
      postcode: [data.address.postcode, [Validators.required, Validators.maxLength(255)]],
      settlement: [data.address.settlement, [Validators.required, Validators.maxLength(255)]],
      street: [data.address.street, [Validators.required, Validators.maxLength(255)]],
    }),
  });

  form.statusChanges
    .pipe(
      debounceTime(1000),
      filter(() => form.valid),
    )
    .subscribe({
      next: async () => {
        try {
          const value = form.value as Client;

          await this.formService.updateClient(value);
        } catch (error) {
          console.error('Error while saving client', error);
          if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
              const errorData = errorResponse.safeParse(error.response?.data);
              if (errorData.success) {
                Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                  form.get(path).setErrors({
                    unknown: errors[0],
                  });
                });
              }
            }
          }
        }
      }
    });

  return form;
}