<payee-header>
  <ng-template #logoTemplate>
    <a [target]="'_blank'" [href]="payeeLink">
      <img
        src="assets/shared/global-images/payee-logos/v1/black-payee-blue-dot.svg"
        width="100px"
      />
    </a>
  </ng-template>
  <ng-template #buttonTemplateRef>
    <div class="buttons flex vcenter">
      <button
        *ngIf="isAuthenticated"
        payee-icon-button
        [isBorderButton]="true"
        color="white"
        class="logout"
        (click)="logout()"
      >
        <payee-icon name="logout-grey"></payee-icon>
      </button>
      <payee-icon
        [name]="'gdpr'"
        [width]="'120px'"
        [height]="'48px'"
      ></payee-icon>
    </div>
  </ng-template>

  <ng-template #payeeHeaderLogoTemplate>
    <ng-container
      [ngTemplateOutlet]="logoTemplateRef || logoTemplate"
    ></ng-container>
  </ng-template>
  <ng-template #payeeHeaderButtonTemplate *ngIf="buttonTemplateRef">
    <ng-container [ngTemplateOutlet]="buttonTemplateRef"></ng-container>
  </ng-template>
  <ng-content></ng-content>
</payee-header>
