import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AxiosError } from 'axios';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, skip, startWith, takeUntil } from 'rxjs/operators';
import { CasesService, GetCardPaymentStatus, PaymentStatus } from 'src/app/services/cases/cases.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CLIENT_FORM_NAME, DEBTOR_FORM_NAME, FormName, HU_DEBTOR_FORM_NAME } from 'src/app/services/form/form.factory';
import { Case, CLIENT_FORM_STEP_NAME, DEBTOR_FORM_STEP_NAME, FormService, FormStepName, formStepNames, nextFormStep, PAYMENT_FORM_STEP_NAME, PAYMENT_REDIRECT_FORM_STEP_NAME } from 'src/app/services/form/form.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { errorResponse } from 'src/types/error-response';
import { z } from 'zod';

export const caseNotDraftError = z.object({
  code: z.literal('case-not-draft'),
  payee_case_reference_id: z.string(),
  client_id: z.string(),
  product_type: z.string(),
});

@Component({
  selector: 'app-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.scss']
})
export class CaseFormComponent implements OnInit, OnDestroy {
  get form(): FormGroup { return this.formService.form; };
  formComponents: { name: FormName, component: ComponentType<unknown>; }[] = [];
  get case(): Case { return this.formService.case; };
  private canShowSpinnerTimeout?: NodeJS.Timeout;
  canShowSpinner = false;
  showLoadingScreen = true;
  loading = true;
  private caseId!: string;
  private step?: FormStepName;
  private readonly destroy = new Subject<void>();
  private readonly formChanged = new Subject<void>();

  private readonly paymentInProgressStatuses: PaymentStatus[] = [
    'Started',
    'InProgress',
    'Waiting',
    'Reserved',
    'Authorized',
  ];
  private paymentStatus?: GetCardPaymentStatus;
  private paymentRedirectRetryInterval?: NodeJS.Timeout;
  private retryCount = 10;

  get saving(): boolean { return this.formService.saving.value > 0; }
  get justSaved(): boolean { return this.formService.justSaved.value > 0; }
  get showNextPageButton(): boolean { return !!nextFormStep[this.step]; }

  hasUnsavedChanges = false;

  constructor(
    private casesService: CasesService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private helpersService: HelpersService,
    private authService: AuthService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.formService.campaign.utm_campaign = this.route.snapshot.queryParams.utm_campaign;
    this.formService.campaign.utm_content = this.route.snapshot.queryParams.utm_content;
    this.formService.campaign.utm_medium = this.route.snapshot.queryParams.utm_medium;
    this.formService.campaign.utm_source = this.route.snapshot.queryParams.utm_source;

    this.route.params
      .pipe(
        filter(params => !this.step || params.step !== this.step)
      )
      .subscribe({
        next: async params => {
          this.canShowSpinnerTimeout = setTimeout(() => this.canShowSpinner = true, 200);
          this.loading = true;
          this.caseId = params.uuid;

          if (!this.authService.isAuthenticated) {
            // If not logged in try short token authentication
            const shortToken = this.route.snapshot.queryParams.short_token;
            if (shortToken) {
              try {
                await this.authService.loginWithShortToken(shortToken);
              } catch (error) {
                console.warn('Error while logging in with short token', error);
              }
            }
          }

          // Create case
          if (!this.caseId) {
            if (!this.authService.isAuthenticated) {
              await this.formService.saveCampaignLocally();
              this.router.navigate([`/case/guest`], {
                queryParamsHandling: 'merge',
              });
              return;
            }
            await this.clientsService.getSelectedClient();
            const result = await this.casesService.createCase({
              product_type: 'hard_1',
              utm_campaign: this.formService.campaign.utm_campaign,
              utm_content: this.formService.campaign.utm_content,
              utm_medium: this.formService.campaign.utm_medium,
              utm_source: this.formService.campaign.utm_source,
            });
            this.router.navigate([`/case/${result.id}`], {
              queryParamsHandling: 'merge',
            });
            return;
          }

          // Redirect unknown step
          if (!params.step || !formStepNames.includes(params.step)) {
            this.router.navigate([`/case/${this.caseId}/${DEBTOR_FORM_STEP_NAME}`], {
              queryParamsHandling: 'merge',
            });
            return;
          }
          this.formService.setCaseId(this.caseId);

          if (this.formService.isGuest) {
            await this.formService.saveCampaignLocally();
          }

          this.step = params.step as FormStepName;
          const paymentId = this.route.snapshot.queryParams.paymentId;
          if (this.step === PAYMENT_REDIRECT_FORM_STEP_NAME && !paymentId) {
            this.router.navigate([`/case/${this.caseId}/${DEBTOR_FORM_STEP_NAME}`], {
              queryParamsHandling: 'merge',
            });
            return;
          }

          this.setTitle();

          try {
            await this.setForm();
          } catch (error: AxiosError | unknown) {
            if (error instanceof AxiosError) {
              if (error.response?.status === 401) {
                return;
              }
              const notDraftError = caseNotDraftError.safeParse(error.response?.data);
              if (notDraftError.success) {
                await this.clientsService.getSelectedClient();
                const client = this.clientsService.clientsSubject.value?.find(c => c.id === notDraftError.data.client_id);
                if (client) {
                  this.clientsService.setSelectedClient(client);
                }

                this.router.navigate(['/user/cases'], {
                  queryParams: {
                    filterType: 'payeeId',
                    filter: notDraftError.data.payee_case_reference_id,
                  },
                });
                return;
              }

              const notValidError = errorResponse.safeParse(error.response?.data);
              if (notValidError.success) {
                this.router.navigate([`/case/${this.caseId}/${DEBTOR_FORM_STEP_NAME}`], {
                  queryParamsHandling: 'merge',
                });
                return;
              }

              console.error('Network error while loading case', error.response?.data);
            } else {
              console.error('Unknown error while loading case', error);
            }

            this.router.navigate(['/user/cases']);
            return;
          }
          if (this.step === PAYMENT_REDIRECT_FORM_STEP_NAME) {
            await this.checkPayment();
          }

          const debtorForms: FormName[] = [
            HU_DEBTOR_FORM_NAME,
            DEBTOR_FORM_NAME,
          ];
          const debtorFormName = debtorForms.find(form => this.form.get(form));
          const jurisdictionForm = this.form.get([debtorFormName, 'jurisdiction']);
          const queryJurisdiction = this.route.snapshot.queryParams.jurisdiction;
          if (
            jurisdictionForm
            && queryJurisdiction
          ) {
            setTimeout(() => jurisdictionForm?.patchValue(queryJurisdiction));
          }

          clearTimeout(this.canShowSpinnerTimeout);
          this.canShowSpinner = false;
          this.loading = false;
          this.showLoadingScreen = false;
        }
      });

    this.formService.saving
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: value => {
          if (value <= 0) {
            this.hasUnsavedChanges = false;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.formChanged.complete();
  }

  async nextPage(): Promise<void> {
    if (this.formService.form.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.formService.form);
      return;
    }

    if (this.saving || this.hasUnsavedChanges) {
      return;
    }

    if (this.step === CLIENT_FORM_STEP_NAME && this.formService.isGuest) {
      await this.registerGuest();
    } else if (nextFormStep[this.step]) {
      this.router.navigate([`/case/${this.caseId}/${nextFormStep[this.step]}`], {
        queryParamsHandling: 'merge',
      });
    } else {
      console.error('Next step not found', {
        step: this.step,
        nextFormStep,
      });
    }
  }

  /**
   * @throws {never}
   */
  private async setForm(): Promise<void> {
    this.formChanged.next();
    await this.formService.setFormData(this.step || DEBTOR_FORM_STEP_NAME);
    this.formService.setFormGroup();
    this.formComponents = this.formService.getFormComponents();

    const debtorForms: FormName[] = [
      HU_DEBTOR_FORM_NAME,
      DEBTOR_FORM_NAME,
    ];
    const debtorFormName = debtorForms.find(form => this.form.get(form));
    if (debtorFormName) {
      const jurisdictionForm = this.form.get([debtorFormName, 'jurisdiction']);
      jurisdictionForm?.valueChanges
        .pipe(
          takeUntil(this.destroy),
          takeUntil(this.formChanged),
          startWith(jurisdictionForm.value),
          distinctUntilChanged(),
          skip(1),
        )
        .subscribe(
          async () => {
            // Wait for save to start
            await this.formService.saving
              .pipe(
                filter(v => v > 0),
                first(),
              )
              .toPromise();
            // Wait for save to end
            await this.formService.saving
              .pipe(
                filter(v => v <= 0),
                first(),
              )
              .toPromise();
            await this.setForm();
          });
    }

    Object.values(this.form.controls).forEach(form => {
      form.valueChanges
        .pipe(takeUntil(this.formChanged))
        .subscribe(() => {
          this.hasUnsavedChanges = form.valid;
        });
    });
    this.hasUnsavedChanges = false;
  }

  private setTitle(): void {
    switch (this.step) {
      case DEBTOR_FORM_STEP_NAME: return this.titleService.setTitle('Adatmegadás - Az Ön követelései - Payee');
      case CLIENT_FORM_NAME: return this.titleService.setTitle('Adatmegadás - Az Ön Cége - Payee');
      case PAYMENT_FORM_STEP_NAME: return this.titleService.setTitle('Rendelés befejezése - Payee');
      case PAYMENT_REDIRECT_FORM_STEP_NAME: return this.titleService.setTitle('Rendelés befejezése - Payee');
    }
  }

  private async checkPayment(): Promise<void> {
    return new Promise((res, rej) => {
      this.paymentRedirectRetryInterval = setInterval(async () => {
        try {
          const paymentId = this.route.snapshot.queryParams.paymentId;
          this.paymentStatus = await this.casesService.getCardPaymentStatus(paymentId, this.caseId);
        } catch (error) {
          console.error('Error while getting payment status', error);
          await this.router.navigate([`/case/${this.caseId}/payment`], {
            queryParamsHandling: 'merge',
          });
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        }

        if (this.paymentStatus?.status === 'Succeeded') {
          this.casesService.caseOpenedGtmEvent(
            this.case.type,
            this.case.payee_case_reference_id,
          );
          await this.router.navigate(['/user/cases'], {
            queryParams: {
              filter: this.case.payee_case_reference_id,
              filterType: 'payeeId',
            },
          });
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        } else if (!this.paymentInProgressStatuses.includes(this.paymentStatus.status)) {
          await this.router.navigate([`/case/${this.caseId}/payment`], {
            queryParamsHandling: 'merge',
          });
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        }

        --this.retryCount;
        if (this.retryCount <= 0) {
          clearInterval(this.paymentRedirectRetryInterval);
          await this.router.navigate([`/case/${this.caseId}/payment`], {
            queryParamsHandling: 'merge',
          });
          res();
          return;
        }
      }, 1000);
    });
  }

  private async registerGuest(): Promise<void> {
    try {
      this.loading = true;
      this.canShowSpinnerTimeout = setTimeout(() => this.canShowSpinner = true, 250);

      await this.formService.registerGuest();
    } catch (error) {
      console.error('Error while registering guest', error);
      if (error instanceof AxiosError) {
        const validationError = errorResponse.safeParse(error.response?.data);
        if (validationError.success) {
          this.helpersService.markAllChildrenAsDirty(this.form);
          for (const [path, errors] of Object.entries(validationError.data.errors)) {
            const control = this.form.get(path);
            const error = {
              unknown: errors[0],
            };
            control?.setErrors(error, { emitEvent: false });
          }
        }
      }
    } finally {
      clearTimeout(this.canShowSpinnerTimeout);
      this.canShowSpinner = false;
      this.loading = false;
    }
  }
}
