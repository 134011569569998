<payee-progress-bar
  [(selectedIndex)]="selectedIndex"
  [linear]="true"
  [xLink]="leaveLink"
>
  <ng-template #headerLogoTemplate>
    <img
      src="assets/shared/global-images/payee-logos/v1/black-payee-blue-dot.svg"
      width="100px"
    />
  </ng-template>

  <ng-template #headerButtonTemplate>
    <button
      payee-icon-button
      color="white"
      [routerLink]="leaveLink"
      type="button"
    >
      <payee-icon name="cross-black"></payee-icon>
    </button>
  </ng-template>

  <ng-template #nextButton let-index>
    <button
      payee-button
      payee-progress-bar-next
      width="wide"
      height="tall"
      type="button"
    >
      {{ "ADD_NEW_CLIENT.FORM.NEXT_BUTTON" | translate }}
    </button>
  </ng-template>

  <payee-step
    [label]="'ADD_NEW_CLIENT.FORM.BASE.BASIC_INFO' | translate"
    [formGroup]="!isClientInd ? orgBasicsForm : indBasicsForm"
  >
    <ng-template #nameInputTemplate>
      <payee-company-search-input-field
        width="400px"
        height="46px"
        [name]="name.value"
        [label]="'ADD_NEW_CLIENT.FORM.BASE.COMPANY_SEARCH_LABEL' | translate"
        [canBeInd]="false"
        [canBeHouse]="false"
        (individualSelected)="fillWithIndValues($event)"
        (houseSelected)="fillWithCompanyValues($event)"
        (companySearchItemSelected)="fillWithCompanyValues($event)"
      >
        <ng-container payee-error *ngIf="name.dirty">
          <div *ngIf="name.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-company-search-input-field>
    </ng-template>

    <ng-template #basicsTitleTemplate>
      <div class="title-box flex vcenter column">
        <h1>{{ "ADD_NEW_CLIENT.FORM.BASE.WHO_IS_CREDITOR" | translate }}</h1>
        <!-- TODO: tooltip -->

        <payee-info *ngIf="isSzamlazzhu" class="szamlazzhu-warning">
          {{ "ADD_NEW_CLIENT.FORM.BASE.SZAMLAZZHU_WARNING" | translate }}
        </payee-info>
      </div>
    </ng-template>

    <app-form-template *ngIf="!isClientInd" [formGroup]="orgBasicsForm">
      <ng-template #titleTemplate>
        <ng-container [ngTemplateOutlet]="basicsTitleTemplate"></ng-container>
      </ng-template>
      <ng-container [ngTemplateOutlet]="nameInputTemplate"></ng-container>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BASE.TAX_NUMBER" | translate }}
        </div>
        <input payee-input formControlName="taxNumber" />
        <ng-container payee-error *ngIf="taxNumber.dirty">
          <div *ngIf="taxNumber.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BASE.REGISTRATION_NUMBER" | translate }}
        </div>
        <input payee-input formControlName="registrationNumber" />
        <ng-container payee-error *ngIf="registrationNumber.dirty">
          <div *ngIf="registrationNumber.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>
    </app-form-template>

    <app-form-template
      *ngIf="isClientInd && indBasicsForm"
      [formGroup]="indBasicsForm"
    >
      <ng-template #titleTemplate>
        <ng-container [ngTemplateOutlet]="basicsTitleTemplate"></ng-container>
      </ng-template>
      <ng-container [ngTemplateOutlet]="nameInputTemplate"></ng-container>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BASE.MOTHERS_NAME" | translate }}
        </div>
        <input payee-input formControlName="mothersName" />
        <ng-container payee-error *ngIf="mothersName.dirty">
          <div *ngIf="mothersName.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BASE.BIRTH_PLACE" | translate }}
        </div>
        <input payee-input formControlName="birthPlace" />
        <ng-container payee-error *ngIf="birthPlace.dirty">
          <div *ngIf="birthPlace.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BASE.BIRTH_DATE" | translate }}
        </div>
        <ng-container payee-error *ngIf="birthDate.dirty">
          <div *ngIf="birthDate.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
        <payee-datepicker formControlName="birthDate"> </payee-datepicker>
      </payee-form-field>
    </app-form-template>

    <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
  </payee-step>

  <payee-step
    [label]="'ADD_NEW_CLIENT.FORM.ADDRESS.TITLE' | translate"
    [formGroup]="addressForm"
  >
    <app-form-template [formGroup]="addressForm">
      <ng-template #titleTemplate>
        <h1 *ngIf="!isClientInd">
          {{ "ADD_NEW_CLIENT.FORM.ADDRESS.HEADQUARTERS" | translate: {
            clientName
          } }}
        </h1>
        <!-- TODO: tooltip -->
        <h1 *ngIf="isClientInd">
          {{ "ADD_NEW_CLIENT.FORM.ADDRESS.RESIDENCE" | translate: {
            clientName
          } }}
        </h1>
        <!-- TODO: tooltip -->
      </ng-template>

      <payee-dropdown
        formControlName="country"
        height="tall"
        width="wide"
        [options]="countries"
      >
        <ng-template #menuDisplayTemplate let-selected>
          <div class="flex vcenter" *ngIf="selected">
            <span class="grow1">{{ selected.label | translate }}</span>
            <img [src]="selected.iconUrl" alt="" width="25" height="25" />
          </div>
        </ng-template>
        <ng-template #itemDisplayTemplate let-option>
          <ng-container
            [ngTemplateOutlet]="menuDisplayTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>
        </ng-template>

        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.ADDRESS.COUNTRY" | translate }}
        </div>
        <ng-container payee-error *ngIf="country.dirty">
          <div *ngIf="country.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-dropdown>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.ADDRESS.POSTCODE" | translate }}
        </div>
        <input
          payee-input
          formControlName="postcode"
          #addresstext
          placeholder=""
        />
        <ng-container payee-error *ngIf="postcode.dirty">
          <div *ngIf="postcode.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
          <div *ngIf="postcode.hasError('pattern')">
            {{ "ERROR_MESSAGES.INVALID_FORMAT" | translate }}
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.ADDRESS.SETTLEMENT" | translate }}
        </div>
        <input payee-input formControlName="settlement" />
        <ng-container payee-error *ngIf="settlement.dirty">
          <div *ngIf="settlement.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.ADDRESS.STREET" | translate }}
        </div>
        <input payee-input formControlName="street" />
        <ng-container payee-error *ngIf="street.dirty">
          <div *ngIf="street.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>
    </app-form-template>

    <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
  </payee-step>

  <payee-step
    [label]="'ADD_NEW_CLIENT.FORM.BANK_ACCOUNT.TITLE' | translate"
    [formGroup]="bankAccountForm"
  >
    <app-form-template [formGroup]="bankAccountForm">
      <ng-template #titleTemplate>
        <h1>{{ "ADD_NEW_CLIENT.FORM.BANK_ACCOUNT.DETAILS" | translate }}</h1>
        <!-- TODO: tooltip -->
      </ng-template>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BANK_ACCOUNT.BENEFICIARY_NAME" | translate }}
        </div>
        <input payee-input formControlName="beneficiaryName" />
        <ng-container payee-error *ngIf="beneficiaryName.dirty">
          <div *ngIf="beneficiaryName.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BANK_ACCOUNT.IBAN" | translate }}
        </div>
        <input
          payee-input
          formControlName="bankAccount"
          (input)="formatBankAccount(bankAccount)"
        />
        <ng-container payee-error *ngIf="bankAccount.dirty">
          <div *ngIf="bankAccount.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
          <div *ngIf="bankAccount.hasError('pattern')">
            {{ "ERROR_MESSAGES.INVALID_FORMAT" | translate }}
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">
          {{ "ADD_NEW_CLIENT.FORM.BANK_ACCOUNT.BANK_NAME" | translate }}
        </div>
        <input payee-input formControlName="bankName" />
        <ng-container payee-error *ngIf="bankName.dirty">
          <div *ngIf="bankName.hasError('required')">
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </div>
        </ng-container>
      </payee-form-field>
    </app-form-template>

    <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
  </payee-step>

  <payee-step
    [label]="'ADD_NEW_CLIENT.FORM.COMMUNICATION.TITLE' | translate"
    [formGroup]="toneOfVoiceForm"
  >
    <app-form-template
      (submit)="onSubmit()"
      class="tov-form"
      [formGroup]="toneOfVoiceForm"
      [title]="'ADD_NEW_CLIENT.FORM.COMMUNICATION.HEADER' | translate"
    >
      <payee-info width="wide">
        {{ "ADD_NEW_CLIENT.FORM.COMMUNICATION.MODIFY_LATER" | translate }}
      </payee-info>

      <!-- Tone of Voice -->
      <div>
        <div class="dropdown-title-container">
          <h2 class="dropdown-title">
            {{ "ADD_NEW_CLIENT.FORM.COMMUNICATION.TONE_OF_VOICE" | translate }}
          </h2>
        </div>
        <div class="description">
          {{
            "ADD_NEW_CLIENT.FORM.COMMUNICATION.TONE_DESCRIPTION"
              | translate : { clientName: clientName }
          }}
        </div>
        <payee-dropdown
          class="dropdown"
          [options]="tovs"
          formControlName="toneOfVoice"
          height="tall"
          width="wide"
        >
          <ng-template #menuDisplayTemplate let-option>
            <span class="bold">{{ option.label | translate }}</span>
            <payee-icon
              *ngIf="toneOfVoice.value.value === 'friendly'"
              class="tooltip"
              [name]="'questionmark-grey-circle'"
              matTooltip="{{
                'ADD_NEW_CLIENT.FORM.COMMUNICATION.TOOLTIP_FRIENDLY' | translate
              }}"
              matTooltipPosition="right"
              matTooltipClass="custom-tooltip"
            >
            </payee-icon>
            <payee-icon
              *ngIf="toneOfVoice.value.value === 'formal'"
              class="tooltip"
              [name]="'questionmark-grey-circle'"
              matTooltip="{{
                'ADD_NEW_CLIENT.FORM.COMMUNICATION.TOOLTIP_FORMAL' | translate
              }}"
              matTooltipPosition="right"
              matTooltipClass="custom-tooltip"
            >
            </payee-icon>
          </ng-template>
          <ng-template #itemDisplayTemplate let-option>
            <span class="bold">{{ option.label | translate }}</span>
          </ng-template>
          <ng-container payee-error *ngIf="toneOfVoice.dirty">
            <div *ngIf="toneOfVoice.hasError('required')">
              {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
            </div>
          </ng-container>
        </payee-dropdown>
      </div>

      <!-- Credit Card Payment -->
      <div>
        <div class="dropdown-title-container">
          <h2 class="dropdown-title">
            {{
              "ADD_NEW_CLIENT.FORM.COMMUNICATION.CREDIT_CARD_PAYMENT"
                | translate
            }}
          </h2>
        </div>
        <div class="description">
          {{
            "ADD_NEW_CLIENT.FORM.COMMUNICATION.CREDIT_CARD_DESCRIPTION"
              | translate
          }}
          <a
            class="transaction-fee-link"
            href="https://help.payee.tech/hu/articles/6460278-milyen-dijakat-kell-fizetni-a-szolgaltatas-hasznalata-soran"
            target="_blank"
          >
            {{
              "ADD_NEW_CLIENT.FORM.COMMUNICATION.TRANSACTION_FEE_LINK"
                | translate
            }}
          </a>
        </div>
        <payee-dropdown
          [options]="creditCardOptions"
          formControlName="creditCardPayment"
          height="tall"
          width="wide"
        >
          <ng-template #menuDisplayTemplate let-option>
            <span class="bold">{{ option.label | translate }}</span>
          </ng-template>
          <ng-template #itemDisplayTemplate let-option>
            <span class="bold">{{ option.label | translate }}</span>
          </ng-template>
          <ng-container payee-error *ngIf="creditCardPayment.dirty">
            <div *ngIf="creditCardPayment.hasError('required')">
              {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
            </div>
          </ng-container>
        </payee-dropdown>
      </div>

      <button
        payee-button
        class="finish-button"
        width="wide"
        [disabled]="clientForms.invalid || createClientLoading"
      >
        <ng-container *ngIf="!createClientLoading">
          {{ "ADD_NEW_CLIENT.FORM.COMMUNICATION.FINISH_BUTTON" | translate }}
        </ng-container>
        <div class="flex hcenter vcenter bw-theme" *ngIf="createClientLoading">
          <mat-progress-spinner
            color="accent"
            mode="indeterminate"
            diameter="30"
          ></mat-progress-spinner>
        </div>
      </button>
    </app-form-template>
  </payee-step>

  <button
    *ngIf="selectedIndex > 0"
    class="prev-button"
    payee-button
    payee-progress-bar-prev
    width="shorter"
    type="button"
    color="white"
  >
    <div class="flex hcenter vcenter">
      <img src="assets/shared/global-images/icons/arrow-left-grey.svg" />
      <span color="grey-dark">{{
        "ADD_NEW_CLIENT.FORM.BACK_BUTTON" | translate
      }}</span>
    </div>
  </button>

  <button
    *ngIf="selectedIndex === 0"
    class="prev-button"
    payee-button
    width="shorter"
    type="button"
    color="white"
    [routerLink]="leaveLink"
  >
    <div class="flex hcenter vcenter">
      <img src="assets/shared/global-images/icons/arrow-left-grey.svg" />
      <span color="grey-dark">{{
        "ADD_NEW_CLIENT.FORM.BACK_BUTTON" | translate
      }}</span>
    </div>
  </button>
</payee-progress-bar>
