import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FormExitWarningDialogComponent
} from 'src/app/aa-new-form/dialogs/form-exit-warning-dialog/form-exit-warning-dialog.component';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { CLIENT_FORM_STEP_NAME, DEBTOR_FORM_STEP_NAME, FormService, FormStepName, PAYMENT_FORM_STEP_NAME } from 'src/app/services/form/form.service';
import payeeIconConfig from 'src/app/shared/payee-icon/payee-icon-config';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit, OnDestroy {
  private readonly destroy = new Subject<void>();
  selectedStepIndex = 0;
  get showPartnerLogo(): boolean { return !!this.formService.case; }
  get partnerLogoName(): string | null {
    const logo = (this.formService.case?.partner_id || 'payee') + '-logo';
    if (logo in payeeIconConfig) {
      return logo;
    }
    return 'payee-logo';
  };

  stepWidth = '206px';
  steps: FormStepName[] = [
    DEBTOR_FORM_STEP_NAME,
    CLIENT_FORM_STEP_NAME,
    PAYMENT_FORM_STEP_NAME,
  ];
  stepNames = new BehaviorSubject<Record<string, string>>({
    debtor: this.translate.instant('CASE_FORM.HEADER.STEPS.DEBTOR'),
    client: this.translate.instant('CASE_FORM.HEADER.STEPS.CLIENT'),
    payment: this.translate.instant('CASE_FORM.HEADER.STEPS.PAYMENT'),
  });

  get isLogoClickable(): boolean { //return !this.caseFormDataService.caseData?.partnerId; TODO
    return true;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private formService: FormService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: params => {
        const step = params.step as FormStepName;
        if (this.steps.includes(step)) {
          this.selectedStepIndex = this.steps.indexOf(step);
        }
      }
    });

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.stepNames.next({
          debtor: this.translate.instant('CASE_FORM.HEADER.STEPS.DEBTOR'),
          client: this.translate.instant('CASE_FORM.HEADER.STEPS.CLIENT'),
          payment: this.translate.instant('CASE_FORM.HEADER.STEPS.PAYMENT'),
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async navigateToStep(index: number) {
    if (index < this.selectedStepIndex) {
      switch (index) {
        case 0: {
          const uuid = this.activatedRoute.snapshot.params['uuid'];
          await this.router.navigate([`/case/${uuid}/debtor`]);
          this.selectedStepIndex = index;
          break;
        }
        case 1: {
          const uuid = this.activatedRoute.snapshot.params['uuid'];
          await this.router.navigate([`/case/${uuid}/client`]);
          this.selectedStepIndex = index;
          break;
        }
      }
    }
  }

  onLogoClick() {
    if (!this.isLogoClickable) {
      return;
    }

    this.dialog.open(FormExitWarningDialogComponent, {
      data: {
        payeeId: this.formService.case.payee_case_reference_id,
      }
    });
  }

  onExitButtonClick() {
    this.dialog.open(FormExitWarningDialogComponent, {
      data: {
        payeeId: this.formService.case.payee_case_reference_id,
      }
    });
  }

  readonly environment = environment;
}
