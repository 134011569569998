<h2>{{ "CASE_FORM.FORMS.USER_EMAIL.TITLE" | translate }}</h2>
<form [formGroup]="form" class="top-margin-15">
  <payee-form-field width="widest" height="tall">
    <input
      payee-input
      [placeholder]="
        'CASE_FORM.FORMS.USER_EMAIL.EMAIL_PLACEHOLDER' | translate
      "
      formControlName="email"
    />
    <ng-container payee-error *ngIf="userEmail.invalid && userEmail.dirty">
      <span *ngIf="userEmail.hasError('required')">
        {{
          "ERROR_MESSAGES.FIELD_REQUIRED" | translate
        }}
      </span>
      <span *ngIf="userEmail.hasError('email')">
        {{
          "ERROR_MESSAGES.INVALID_FORMAT" | translate
        }}
      </span>
      <span *ngIf="userEmail.hasError('unknown')">{{
        userEmail.errors.unknown
      }}</span>
    </ng-container>
  </payee-form-field>

  <div class="flex column top-margin-15">
    <mat-checkbox class="tos-checkbox" formControlName="terms_of_service">
      <span>
        {{ "CASE_FORM.FORMS.USER_EMAIL.TOS_TEXT_PART_1" | translate }}
      </span>
      <a
        target="_blank"
        href="https://www.payee.tech/legal/terms-and-conditions"
      >
        {{ "CASE_FORM.FORMS.USER_EMAIL.TOS_LINK" | translate }}
      </a>
      <span>
        {{ "CASE_FORM.FORMS.USER_EMAIL.TOS_TEXT_PART_2" | translate }}
      </span>
      <a target="_blank" href="https://www.payee.tech/legal/privacy-policy">
        {{
          "CASE_FORM.FORMS.USER_EMAIL.PRIVACY_POLICY_LINK" | translate
        }}
      </a>
    </mat-checkbox>
    <mat-error *ngIf="terms_of_service.invalid && terms_of_service.dirty">
      {{ "CASE_FORM.FORMS.USER_EMAIL.ERRORS.TOS_REQUIRED" | translate }}
    </mat-error>
  </div>
</form>

<payee-info class="top-margin-15">
  {{ "CASE_FORM.FORMS.USER_EMAIL.INFO_TEXT" | translate }}
</payee-info>
