import { FormGroup, Validators } from "@angular/forms";
import { FormFactory } from "../form.factory";
import { Client } from "../form.resources";
import moment, { isMoment } from "moment";
import { debounceTime, filter, startWith } from "rxjs/operators";
import { AxiosError } from "axios";
import { errorResponse } from "src/types/error-response";
import { maxDateValidator } from "../validators/max-date";

export function getHuClientForm(this: FormFactory): FormGroup {
  const data = this.data as Client;
  const rep = data.representative;

  const form = this.fb.group({
    id: data?.id ?? (this.formService.isGuest ? new Date().valueOf().toString() : null),
    client_type: [data.client_type, [Validators.required, Validators.pattern(/org|house/)]],
    name: [data.name, [Validators.required, Validators.maxLength(255)]],
    party_type_id: [data.party_type_id, Validators.required],
    // @todo required if org and not house
    tax_number: data.tax_number,
    registration_number: data.registration_number,
    address: this.fb.group({
      country_iso: [data.address.country_iso || 'HU', Validators.required],
      postcode: [data.address.postcode, [Validators.required, Validators.maxLength(255)]],
      settlement: [data.address.settlement, [Validators.required, Validators.maxLength(255)]],
      street: [data.address.street, [Validators.required, Validators.maxLength(255)]],
    }),
    birth_date: [
      moment(data.birth_date).isValid()
        ? moment(data.birth_date)
        : null,
      [
        Validators.required,
        maxDateValidator(moment().subtract(1, 'day').endOf('day'))
      ],
    ],
    birth_place: [data.birth_place, [Validators.required, Validators.maxLength(255)]],
    mother_name: [data.mother_name, [Validators.required, Validators.maxLength(255)]],
    // @todo possibly array
    representative: this.fb.group({
      name: [rep?.name, [Validators.required, Validators.maxLength(255)]],
      address: this.fb.group({
        country_iso: [rep?.address?.country_iso || 'HU', [Validators.required]],
        postcode: [rep?.address?.postcode, [Validators.required, Validators.maxLength(255)]],
        settlement: [rep?.address?.settlement, [Validators.required, Validators.maxLength(255)]],
        street: [rep?.address?.street, [Validators.required, Validators.maxLength(255)]],
      }),
    }),
  });

  const client_type = form.get('client_type');
  const party_type_id = form.get('party_type_id');
  const representative = form.get('representative');
  const birth_date = form.get('birth_date');
  const birth_place = form.get('birth_place');
  const mother_name = form.get('mother_name');

  party_type_id.valueChanges
    .pipe(startWith(data.party_type_id))
    .subscribe({
      next: v => {
        if (v === '2-a') {
          representative.disable({ onlySelf: true, emitEvent: false });
          birth_date.enable({ onlySelf: true, emitEvent: false });
          birth_place.enable({ onlySelf: true, emitEvent: false });
          mother_name.enable({ onlySelf: true, emitEvent: false });
        } else {
          representative.enable({ onlySelf: true, emitEvent: false });
          birth_date.disable({ onlySelf: true, emitEvent: false });
          birth_place.disable({ onlySelf: true, emitEvent: false });
          mother_name.disable({ onlySelf: true, emitEvent: false });
        }
        form.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      },
    });

  client_type.valueChanges
    .pipe(
      startWith(data.client_type),
    )
    .subscribe({
      next: v => {
        if (v === 'org') {
          party_type_id.enable({ onlySelf: true });
        } else {
          party_type_id.patchValue(null);
          party_type_id.disable({ onlySelf: true });
        }
      },
    });

  form.statusChanges
    .pipe(
      debounceTime(1000),
      filter(() => form.valid),
    )
    .subscribe({
      next: async () => {
        try {
          const value = form.value as Client;

          const birth_date = isMoment(value.birth_date)
            ? value.birth_date.format('YYYY-MM-DD')
            : value.birth_date;

          value.birth_date = birth_date;

          await this.formService.updateClient(value);
        } catch (error) {
          console.error('Error while saving client', error);
          if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
              const errorData = errorResponse.safeParse(error.response?.data);
              if (errorData.success) {
                Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                  form.get(path).setErrors({
                    unknown: errors[0],
                  });
                });
              }
            }
          }
        }
      }
    });

  return form;
}