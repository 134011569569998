<form [formGroup]="form">
  <div class="form-group-label top-margin-15">
    {{ 'CASE_FORM.FORMS.DEBTOR.IND.NAME_LABEL' | translate }}
  </div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="name"
        [placeholder]="'CASE_FORM.FORMS.DEBTOR.IND.NAME_PLACEHOLDER' | translate"
      />
      <ng-container *ngIf="name.invalid && name.dirty" payee-error>
        <span *ngIf="name.hasError('required')">
          {{ 'ERROR_MESSAGES.FIELD_REQUIRED' | translate }}
        </span>
        <span *ngIf="name.hasError('maxlength')">
          {{ 'ERROR_MESSAGES.MAXLENGTH' | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="form-group-label top-margin-15">
    {{ 'CASE_FORM.FORMS.DEBTOR.IND.CONTACT_LABEL' | translate }}
  </div>
  <form formGroupName="address">
    <div class="top-margin-15">
      {{ countryDisplayWith(debtorCountry.value) }}
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="postcode"
          [placeholder]="'CASE_FORM.FORMS.DEBTOR.IND.POSTCODE_PLACEHOLDER' | translate"
        />
        <ng-container *ngIf="postcode.invalid && postcode.dirty" payee-error>
          <span *ngIf="postcode.hasError('required')">
            {{ 'ERROR_MESSAGES.FIELD_REQUIRED' | translate }}
          </span>
          <span *ngIf="postcode.hasError('maxlength')">
            {{ 'ERROR_MESSAGES.MAXLENGTH' | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="settlement"
          [placeholder]="'CASE_FORM.FORMS.DEBTOR.IND.SETTLEMENT_PLACEHOLDER' | translate"
        />
        <ng-container *ngIf="settlement.invalid && settlement.dirty" payee-error>
          <span *ngIf="settlement.hasError('required')">
            {{ 'ERROR_MESSAGES.FIELD_REQUIRED' | translate }}
          </span>
          <span *ngIf="settlement.hasError('maxlength')">
            {{ 'ERROR_MESSAGES.MAXLENGTH' | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="street"
          [placeholder]="'CASE_FORM.FORMS.DEBTOR.IND.STREET_PLACEHOLDER' | translate"
        />
        <ng-container *ngIf="street.invalid && street.dirty" payee-error>
          <span *ngIf="street.hasError('required')">
            {{ 'ERROR_MESSAGES.FIELD_REQUIRED' | translate }}
          </span>
          <span *ngIf="street.hasError('maxlength')">
            {{ 'ERROR_MESSAGES.MAXLENGTH' | translate }}
          </span>
        </ng-container>
      </payee-form-field>
    </div>
  </form>
</form>
